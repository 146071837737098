/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { InvitationsApi, InvitationsApiClient } from "@shared/common";
import { observable } from "mobx";

import { APS } from "./APS";
import { connected, ConnectedService } from "./ConnectedService";
import { config } from "@src/config";

import type { HeliosLogger } from "./HeliosLogger";
import type { ImsUser } from "./ims";
import type { ResponseDetail, CapabilitiesData } from "@shared/common";

export const ACCESS_LEVELS = {
    calculating: "calculating", // determining access
    unavailable: "unavailable", // 503, service or access is unavailable
    none: "none", // 404
    unauthorized: "unauthorized", // 403 for review or login
    limited: "limited", // limited access
    full: "full", // full access for review or login
} as const;

export type AccessLevelType =
    (typeof ACCESS_LEVELS)[keyof typeof ACCESS_LEVELS];

type AccountInfo = {
    type?: string;
    userOrgId?: string;
    isAccountInOrg?: boolean;
    userIsEntitled?: boolean;
};

/**
 * The following functions help to determine the access level when the user navigated to Sunrise
 * via a review link.
 */
/**
 * Determines user's access level for this shared resource after the shared resource's
 * availability is confirmed and the user has access to the shared resource.
 */
const checkAssetAccess = (
    assetId: string,
    accountInfo: AccountInfo,
    response: ResponseDetail,
    logger: HeliosLogger,
): AccessLevelType => {
    const capabilities = response.data as CapabilitiesData;
    if (response.status >= 500) {
        return ACCESS_LEVELS.unavailable;
    }
    if (
        response.status > 309 ||
        !capabilities ||
        capabilities.role === "none"
    ) {
        return ACCESS_LEVELS.unauthorized;
    }
    // If user has an entitlement and has viewer role but is in the same org, we will grant full access;
    // otherwise, limited access for viewer:
    //     - in the same org but not sunrise-entitled,
    //     - user not in the same org,
    //     - user account is not type2, or
    //     - user does not have an orgId.
    if (
        (capabilities.role === "viewer" &&
            capabilities.resourceOrgID !== accountInfo.userOrgId) ||
        (capabilities.role !== "none" &&
            (!accountInfo.isAccountInOrg ||
                !accountInfo.userOrgId ||
                !accountInfo.userIsEntitled))
    ) {
        // limited access
        return ACCESS_LEVELS.limited;
    }

    if (!capabilities.canComment) {

        console.error(
            `checkAssetAccess, canComment for asset, ${assetId} is unexpectedly FALSE for this invited user.`,
        );
        logger.logError({ errorCode: "2180" });
        return ACCESS_LEVELS.none;
    }
    if (
        capabilities.role !== "owner" &&
        capabilities.role !== "editor" &&
        (capabilities.canShare || capabilities.publicPrincipalsAllowed)
    ) {
        logger.logError({
            errorCode: "2181",
            resources: [{ compositeId: assetId }],
        });
    }

    // We expect user role to be owner, editor, or viewer in the same org as the resource.
    if (
        capabilities.role !== "owner" &&
        capabilities.role !== "editor" &&
        (capabilities.resourceOrgID !== accountInfo.userOrgId ||
            capabilities.role !== "viewer")
    ) {
        logger.logError({
            errorCode: "2182",
            resources: [{ compositeId: assetId }],
        });
        throw new Error(
            `Unexpected role, ${capabilities.role} for this invited user`,
        );
    }

    return ACCESS_LEVELS.full;
};

/**
 * Confirming the invitation for the resource is required if acceptance is not required from the invitee.
 * @param invitationsApiClient - The api client.
 * @param assetId - The asset urn.
 */
const confirmInvitation = async (
    invitationsApiClient: InvitationsApiClient | undefined,
    assetId: string,
    logger: HeliosLogger,
): Promise<number> => {
    if (!invitationsApiClient) {
        throw new Error("Invitation service API is undefined");
    }
    return invitationsApiClient
        .request("POST", InvitationsApi.confirmInvitation, [assetId])
        .then((response) => {
            // 403, not sharable because of sharing restriction
            // 404, shared resource cannot be found.
            return response.status;
        })
        .catch((err) => {
            const statusCode = err.data.response.status;
            logger.logError({
                errorCode: "2185",
                resources: [{ compositeId: assetId }],
                statusCode,
            });
            return statusCode;
        });
};

/**
 * Returns the resource type and status from the access URL for a resource.
 * TODO: If a share link requires a password, additional data will be required.
 * @param invitationsApiClient - The api client.
 * @param assetId - The asset urn.
 * @returns the access status and the asset resource type, either asset, project, or undefined
 */
const verifyResourceAuth = async (
    invitationsApiClient: InvitationsApiClient | undefined,
    assetId: string,
    logger: HeliosLogger,
): Promise<{ resourceType?: string; status: number }> => {
    if (!invitationsApiClient) {
        throw new Error("Invitation service API is undefined");
    }
    return invitationsApiClient
        .request("GET", InvitationsApi.auth, [assetId])
        .then((response) => {
            // 400, 401, bad request.
            // 403, User does not have permissions for this resource.
            // 404, shared resource cannot be found.
            return {
                status: response.status,
                resourceType: response.data?.resourceType,
            };
        })
        .catch((err) => {
            const statusCode = err.data.response.status;
            logger.logError({
                errorCode: "2186",
                resources: [{ compositeId: assetId }],
                statusCode,
            });
            return statusCode;
        });
};

/**
 * Verify the resource capabilities for this user.
 * @param invitationsApiClient - The api client.
 * @param assetId - The assetId of the resource.
 * @returns The asset's capabilities.
 */
const verifyAssetCapabilities = async (
    invitationsApiClient: InvitationsApiClient | undefined,
    assetId: string,
    accountInfo: AccountInfo,
    logger: HeliosLogger,
) => {
    if (!invitationsApiClient) {
        throw new Error("Invitation service API is undefined");
    }
    return invitationsApiClient
        .request("GET", InvitationsApi.capabilities, [assetId])
        .then((response) => {
            const result = checkAssetAccess(
                assetId,
                accountInfo,
                response as ResponseDetail,
                logger,
            );
            return result;
        })
        .catch(() => {
            logger.logError({
                errorCode: "2187",
                resources: [{ compositeId: assetId }],
            });
            return ACCESS_LEVELS.unauthorized;
        });
};

/**
 * Access Service determines the user access to Sunrise.
 * Either the user account is entitled (APS) or the user navigated via review link in which case,
 * we will determine the level of access using the Invitation services.
 * See APS.tsx for detail on Adobe Access Profile Service.
 */
export class AccessService extends ConnectedService {
    private _imsUser: ImsUser;
    private _logger: HeliosLogger;
    private _aps: APS;
    private _invitationsApiClient: InvitationsApiClient | undefined;
    private _path = "";
    private _reviewAccessRequired = false;
    private _assetResourceType = "";
    private _accessLevel: AccessLevelType = ACCESS_LEVELS.calculating;

    constructor(imsUser: ImsUser, logger: HeliosLogger) {
        super();
        this._logger = logger;
        this._imsUser = imsUser;
        this._aps = new APS(imsUser, logger);
        this._path = window.location.href;
        this.connect();
    }

    async initialize() {
        this._accessLevel = ACCESS_LEVELS.calculating;

        await this._imsUser.connect();
        if (this._imsUser.accessToken === undefined) {
            this._logger.logError({
                errorCode: "2013",
            });
            throw new Error("Access token for user does not exist");
        }

        this._invitationsApiClient = new InvitationsApiClient(
            config.imsClientId,
            config.adobeEnv,
            this._imsUser.accessToken as string,
        );

        if (!this._invitationsApiClient) {
            throw new Error("Could not create the Invitation service client");
        }

        await this._reviewAccessCheck();
    }

    @observable
    public determiningAccess() {
        return this._accessLevel === ACCESS_LEVELS.calculating;
    }

    @connected
    public async accessLevel() {
        this._accessLevel = this._aps.serviceIsUnavailable()
            ? ACCESS_LEVELS.unavailable
            : this._accessLevel;
        if (
            this._accessLevel === ACCESS_LEVELS.unavailable
        ) {
            this._logger.logError({errorCode: "2189"});
            return this._accessLevel;
        }

        const isUserEntitled = await this._aps.isUserEntitled();
        this._accessLevel = isUserEntitled
            ? ACCESS_LEVELS.full
            : ACCESS_LEVELS.unauthorized;
        this._logger.logInfo({infoCode: "10001", resources: [{
            accessLevel: this._accessLevel.toString(),
        }]});
        return this._accessLevel;
    }

    @connected
    public async assetResourceType() {
        if (this._reviewAccessRequired) {
            return this._assetResourceType;
        }
        return undefined;
    }

    /**
     * Share asset review access check:
     * review page url (asset) - {host}/review?urn={asset-urn}&type={"asset"}&viewType={viewType}
     * review page url (project) - {host}/review?urn={asset-urn}&type={"project"}
     * limited access url (asset) - {host}/library/review/{dataId}/{viewType}
     * limited access url (project) - {host}/projects/review/{dataId}
     * full access url (asset) - {host}/library/assets/{dataId}/{viewType}
     * full access url (project) - {host}/projects | projectsLegacy/{dataId}
     */
    private async _reviewAccessCheck() {
        const match = this._path.match("/review(/ar)?");
        this._reviewAccessRequired = match?.length === 2 && !match[1]; // undefined
        if (!this._reviewAccessRequired) {
            return;
        }

        this._accessLevel = ACCESS_LEVELS.calculating;

        const reviewUrl = new URL(this._path);
        const searchParams = reviewUrl.searchParams;
        const assetId = searchParams.get("urn"); // This is either library asset or project composite id.
        const assetType = searchParams.get("type");
        const commentId = searchParams.get("commentId");

        if (!assetId) {
            // Invalid url search parameters
            this._logger.logError({
                errorCode: "2184",
            });
            this._accessLevel = ACCESS_LEVELS.none;
            return;
        }

        // It is possible that assetType is not set if review link is redirected with commentId
        if (!(assetType || commentId)) {
            this._logger.logError({
                errorCode: "2184",
                resources: [{ compositeId: assetId }],
            });
            this._accessLevel = ACCESS_LEVELS.none;
            return;
        }

        // Account type:
        // type1 (Individual), individual account, userOrg is null/undefined
        // type2 (Enterprise, Business), EMS org, userOrg
        // type3 (Federated), EMS account, e.g. adobe.com, userOrg and various associated product account contexts
        // type2 users in the same org will have full access.
        // type1 and type3 should have limited access if invitation is confirmed.
        // TODO: password-protection to allow access.

        try {
            // Check asset permission
            const accountInfo = await this._getAccountInfo();

            const status = await confirmInvitation(
                this._invitationsApiClient,
                assetId,
                this._logger,
            );
            const resourceResult =
                status === 200
                    ? await verifyResourceAuth(
                          this._invitationsApiClient,
                          assetId,
                          this._logger,
                      )
                    : { resourceType: undefined, status };
            if (resourceResult?.resourceType) {
                this._assetResourceType = resourceResult.resourceType;
                this._accessLevel = await verifyAssetCapabilities(
                    this._invitationsApiClient,
                    assetId,
                    accountInfo,
                    this._logger,
                );
            } else if (resourceResult?.status === 403) {
                this._accessLevel = ACCESS_LEVELS.unauthorized;
            } else if (resourceResult?.status >= 500) {
                this._accessLevel = ACCESS_LEVELS.unavailable;
            } else {
                this._accessLevel = ACCESS_LEVELS.none;
            }
        } catch (err) {
            this._accessLevel = ACCESS_LEVELS.none;
            this._logger.logError({
                errorCode: "2194",
                resources: [{ compositeId: assetId }],
            });
        }
    }

    private async _getAccountInfo(): Promise<AccountInfo> {
        return {
            type: this._imsUser.userProfile?.account_type,
            userOrgId: this._imsUser.userProfile?.ownerOrg,
            isAccountInOrg: this._imsUser.isAccountInOrg(),
            userIsEntitled: await this._aps.isUserEntitled(),
        };
    }
}
