/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTRPCReact } from "@trpc/react-query";
import { useEffect, useRef, useState } from "react";

import { useAppContext } from "./AppContext";
import type { TrpcConnection } from "@src/lib/services/RpcConnection";

import type { HeliosApi } from "@components/hermes";
import type { Unarray } from "@shared/types";
import type { CreateTRPCProxyClient } from "@trpc/client";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";

// @ts-ignore
export const rpc = createTRPCReact<HeliosApi.ApiRouter>();

export type RPCRouter = HeliosApi.ApiRouter;
export type RpcClient = ReturnType<(typeof rpc)["useContext"]>["client"];
export type HeliosRPCClient = CreateTRPCProxyClient<HeliosApi.ApiRouter>;
export type RouterOutput = inferRouterOutputs<HeliosApi.ApiRouter>;
export type RouterInput = inferRouterInputs<HeliosApi.ApiRouter>;

export type LoggedInUserProfileData =
    RouterInput["users"]["getLoggedInUserProfileData"];

export type AemConfigInput = RouterInput["orgs"]["setAemConfig"]
export type WorkfrontConfigInput = RouterInput["orgs"]["setWorkfrontConfig"]

export type LibraryAssetsList =
    RouterOutput["libraryAssets"]["getAllListPaged"]["items"];
export type LibraryAssetsFilters =
    RouterInput["libraryAssets"]["getAllListPaged"]["filters"];
export type LibraryAssetListItem = Unarray<LibraryAssetsList>;
export type LibraryAssetCardItemResponse =
    RouterOutput["libraryAssets"]["getCardItem"];
export type LibraryAssetCardItemErrorsResponse =
    RouterOutput["libraryAssets"]["getAssetCardItemErrors"];

export type LibraryAssetCardItem = NonNullable<LibraryAssetCardItemResponse>;
export type LibraryAssetThumbnailResponse =
    RouterOutput["libraryAssets"]["getAssetThumbnail"];
export type LibraryAssetThumbnail = NonNullable<LibraryAssetThumbnailResponse>;
export type LibraryAssetModelForViewerResponse =
    RouterOutput["libraryAssets"]["getModelForViewer"];
export type LibraryAssetModelForViewer =
    NonNullable<LibraryAssetModelForViewerResponse>;
export type LibraryAssetDownloadDataResponse =
    RouterOutput["libraryAssets"]["getArtifactsForDownload"];
export type LibraryAssetDownloadData =
    NonNullable<LibraryAssetDownloadDataResponse>;
export type LibraryAssetOrthoRendersResponse =
    RouterOutput["libraryAssets"]["getOrthoRenders"];
export type LibraryAssetOrthoRenders =
    NonNullable<LibraryAssetOrthoRendersResponse>;
export type LibraryAssetResponse = RouterOutput["libraryAssets"]["get"];
export type LibraryAsset = NonNullable<LibraryAssetResponse>;
export type LibraryAssetProject = Unarray<LibraryAsset["projects"]>;

export type Project = NonNullable<RouterOutput["projects"]["get"]>;
export type ProjectsList = Unarray<
    NonNullable<RouterOutput["projects"]["getAll"]>
>;
export type ProjectListItem = Unarray<
    NonNullable<RouterOutput["projects"]["getAll"]["items"]>
>;
export type ProjectDownloadInfo =
    RouterOutput["projects"]["getProjectDownloadInfo"];

export type ShotListItem = Unarray<RouterOutput["shots"]["getShotsForAsset"]>;
export type ShotItem = RouterOutput["shots"]["getShot"];
export type Shot = RouterOutput["shots"]["getShot"];
export type ShotForCard = Pick<Shot, "id" | "type" | "camera">;
export type ShotThumbnail = RouterOutput["shots"]["getShotThumbnailForCard"];

export type LogErrorInput = Unarray<RouterInput["logger"]["error"]>;
export type LogErrorCode = LogErrorInput["errorCode"];
export type LogInfoInput = Unarray<RouterInput["logger"]["info"]>;
// LogErrorInput and LogInfoInput use the same type for "resources", so we can
//      declare it a single time here.
export type LogResources = NonNullable<LogErrorInput["resources"]>;
export type LogResource = Unarray<LogResources>;

export const OrthoTypes: `${HeliosApi.OrthoRenderType}`[] = [
    "front",
    "right",
    "back",
    "left",
    "top",
];
export const ModelTypes: `${HeliosApi.ModelType}`[] = ["web", "ar", "source"];

let rpcClient: ReturnType<typeof rpc.createClient>;

function getClient(link: TrpcConnection["wsLink"]) {
    if (rpcClient) {
        return rpcClient;
    }
    rpcClient = rpc.createClient({
        links: [link],
    });
    // @ts-ignore
    window.rpcClient = rpcClient;
    return rpcClient;
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 120_000, // 2 minutes
        },
    },
});

export const RpcContextProvider = ({ children }: React.PropsWithChildren) => {
    const { rpcConnection } = useAppContext();
    const [trpcReactClient] = useState(() => getClient(rpcConnection.wsLink));

    return (
        <rpc.Provider client={trpcReactClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </rpc.Provider>
    );
};

// Heartbeat check every 15 seconds
const HEARTBEAT_INTERVAL = 15 * 1000;

export function useHeartbeat() {
    const { rpcConnection } = useAppContext();
    const { client } = rpc.useContext();

    const heartbeatTimer = useRef<number>();

    function clearHeartbeat() {
        if (heartbeatTimer.current) {
            window.clearTimeout(heartbeatTimer.current);
        }
    }

    async function heartbeat() {
        clearHeartbeat();
        try {
            await client.utilities.heartbeat.mutate();
        } catch (err) {
            console.error("RPC heartbeat failed, killing socket", err);
            rpcConnection.closeConnection();
        }
        heartbeatTimer.current = window.setTimeout(
            heartbeat,
            HEARTBEAT_INTERVAL,
        );
    }

    useEffect(() => {
        if (rpcConnection.online) {
            heartbeat();
        }

        return () => {
            clearHeartbeat();
        };
    }, [rpcConnection.online, client]);

    useEffect(() => {
        const callOnFocus = () => {
            console.log("Restarting heartbeat on window focus");
            heartbeat();
        };
        window.addEventListener("focus", callOnFocus);
        return () => {
            window.removeEventListener("focus", callOnFocus);
        };
    }, []);
}

export function useQueryInvalidations() {
    const { rpcConnection } = useAppContext();
    const [initialConnection, setInitialConnection] = useState(false);

    const queryContext = rpc.useContext();

    useEffect(() => {
        if (rpcConnection.online && !initialConnection) {

            console.log("initial connection");
            setInitialConnection(true);
        }
        if (initialConnection && !rpcConnection.online) {
            queryClient.invalidateQueries();
        }
    }, [rpcConnection.online, initialConnection]);

    useEffect(() => {
        if (queryContext && rpcConnection.online) {
            const libAssetSub =
                queryContext.client.libraryAssets.onChange.subscribe(
                    undefined,
                    {
                        onData({ id } = {}) {
                            if (id) {
                                queryContext.libraryAssets.get.invalidate(id);
                                queryContext.libraryAssets.getCardItem.invalidate(
                                    id,
                                );
                                queryContext.libraryAssets.getAssetThumbnail.invalidate(
                                    id,
                                );
                                queryContext.libraryAssets.getModelForViewer.invalidate(
                                    id,
                                );
                                queryContext.libraryAssets.getArModelInfo.invalidate(
                                    id,
                                );
                                queryContext.libraryAssets.getOrthoRenders.invalidate(
                                    id,
                                );
                                queryContext.libraryAssets.getArtifactsForDownload.invalidate(
                                    id,
                                );
                                queryContext.libraryAssets.canUserModify.invalidate(
                                    id,
                                );
                                queryContext.libraryAssets.isUserOwner.invalidate(
                                    id,
                                );
                                queryContext.libraryAssets.getArInfo.invalidate(
                                    id,
                                );
                                queryContext.libraryAssets.getArUrls.invalidate(
                                    id,
                                );
                                queryContext.libraryAssets.isAssetApproved.invalidate(
                                    id,
                                );
                                queryContext.libraryAssets.isUsedInProjects.invalidate(
                                    id,
                                )
                            }
                        },
                    },
                );

            const assetLibSub =
                queryContext.client.libraryAssets.onLibraryChange.subscribe(
                    undefined,
                    {
                        onData() {
                            queryContext.libraryAssets.getAllListPaged.invalidate();
                            queryContext.libraryAssets.getCount.invalidate();
                        },
                    },
                );

            const reviewableAssetsSub = queryContext.client.libraryAssets.onReviewableAssetsChanged.subscribe(undefined, {
                onData() {
                    queryContext.libraryAssets.getCount.invalidate({
                        filters: ["reviewable"],
                    });
                    queryContext.libraryAssets.getAllListPaged.invalidate({
                        filters: ["processing", "myAssets"],
                    });
                    queryContext.libraryAssets.getCount.invalidate({
                        filters: ["processing", "myAssets"],
                    });
                }
            })


            const approvedAssetsSub =
                queryContext.client.libraryAssets.onApprovedAssetsChange.subscribe(
                    undefined,
                    {
                        onData() {
                            queryContext.libraryAssets.getApprovedList.invalidate();
                            queryContext.libraryAssets.getCount.invalidate({
                                filters: ["approved"],
                            });
                            queryContext.libraryAssets.getCount.invalidate({
                                filters: ["reviewable"],
                            });
                        },
                    },
                );

            const projectSub = queryContext.client.projects.onChange.subscribe(
                undefined,
                {
                    onData({ id } = {}) {
                        if (id) {
                            queryContext.projects.get.invalidate(id);
                            queryContext.projects.getProjectWithOwner.invalidate(
                                id,
                            );
                            queryContext.projects.getProjectName.invalidate(id);
                            queryContext.projects.isProjectApproved.invalidate(
                                id,
                            );
                            queryContext.projects.getAssetShots.invalidate({
                                projectId: id,
                            });
                            queryContext.projects.getAssetIdsInProject.invalidate(
                                id,
                            );
                            queryContext.projects.getAssetsInProjectPaged.invalidate(
                                { projectId: id },
                            );
                            queryContext.projects.hasRenderJobsInProgress.invalidate(
                                id,
                            );
                            queryContext.projects.canUserModify.invalidate(id);
                            queryContext.projects.isUserOwner.invalidate(id);
                            queryContext.projects.getAll.invalidate(); // for filtered property changes
                            queryContext.projects.getProjectDownloadInfo.invalidate(
                                id,
                            );
                            queryContext.shots.getShotsForAsset.invalidate({
                                projectId: id,
                            });
                            queryContext.shots.getSiblingShots.invalidate({
                                projectId: id,
                            });
                            queryContext.shots.get2DShotsInProject.invalidate(
                                id,
                            );
                            queryContext.shots.getIncompleteJobsStatusForProject.invalidate(
                                id,
                            );
                            queryContext.shots.getAllShotCompositeIdsForProject.invalidate(
                                id,
                            );
                        }
                    },
                },
            );
            const shotSub = queryContext.client.shots.onChange.subscribe(
                undefined,
                {
                    onData({ id } = {}) {
                        if (id) {
                            queryContext.shots.getShot.invalidate(id);
                            queryContext.shots.getShotModel.invalidate(id);
                            queryContext.shots.getShotModelJob.invalidate(id);
                            queryContext.shots.getShotRender.invalidate(id);
                            queryContext.shots.getShotThumbnailForCard.invalidate(
                                id,
                            );
                            queryContext.shots.getSiblingShots.invalidate({
                                shotId: id,
                            });
                        }
                    },
                },
            );
            const projectLibSub =
                queryContext.client.projects.onLibraryChange.subscribe(
                    undefined,
                    {
                        onData() {
                            queryContext.projects.getAll.invalidate();
                        },
                    },
                );

            return () => {
                libAssetSub.unsubscribe();
                assetLibSub.unsubscribe();
                approvedAssetsSub.unsubscribe();
                reviewableAssetsSub.unsubscribe();
                projectSub.unsubscribe();
                projectLibSub.unsubscribe();
                shotSub.unsubscribe();
            };
        }
        return () => {};
    }, [queryContext, rpcConnection.online]);
}
