/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, View } from "@adobe/react-spectrum";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppContext } from "./AppContext";
import { AppHeader } from "@src/interfaces/common/components/AppHeader";
import { DelayedSpinner } from "@src/interfaces/common/components/DelayedSpinner";
import { useTermsCheck } from "@src/interfaces/common/hooks/useTermsCheck";
import type { AccessLevelType } from "@src/lib/services/AccessService";
import { ACCESS_LEVELS } from "@src/lib/services/AccessService";

import type { PropsWithChildren } from "react";

interface AccessContextProps {
    accessLevel: AccessLevelType;
    assetResourceType: string | undefined;
}

export const AccessContext = createContext<AccessContextProps | undefined>(
    undefined,
);

export const AccessContextProvider = ({ children }: PropsWithChildren) => {
    const { t } = useTranslation();
    const { accessService } = useAppContext();
    const { didUserAcceptTerms } = useTermsCheck();

    const [accessLevel, setAccessLevel] = useState<AccessLevelType>(
        ACCESS_LEVELS.calculating,
    );
    const [assetResourceType, setAssetResourceType] = useState<
        string | undefined
    >();

    const updateAccessLevel = async () => {
        const level = await accessService.accessLevel()
        const resourceType = await accessService.assetResourceType()
        console.log("Access level", level)
        console.log("resourceType", resourceType)
        setAssetResourceType(resourceType);
        setAccessLevel(level);
    };

    useEffect(() => {
        updateAccessLevel();
    }, []);

    return (
        <AccessContext.Provider
            value={{
                accessLevel,
                assetResourceType,
            }}>
            {accessLevel === "calculating" ? (
                // Dummy page
                <View backgroundColor="gray-50" height="100dvh">
                    <Flex direction="column" height="100%">
                        <AppHeader setPortal={() => {}} />
                        <DelayedSpinner
                            size="L"
                            description={
                                didUserAcceptTerms
                                    ? t("account.legal.checkingPrerelease")
                                    : t("account.access")
                            }
                            delayTime={250}
                        />
                    </Flex>
                </View>
            ) : (
                children
            )}
        </AccessContext.Provider>
    );
};

export const useAccessContext = () => {
    const context = useContext(AccessContext);
    if (!context) {
        throw new Error(
            "useAccessContext must be used within a AccessContextProvider.",
        );
    }
    return context;
};
