/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Flex,
    Heading,
    Tooltip,
    TooltipTrigger,
    View,
} from "@adobe/react-spectrum";
import Alert from "@spectrum-icons/workflow/Alert";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ActivityItemWrapper } from "./ActivityItemWrapper";
import { useRedirects } from "../hooks/useRedirects";
import {
    PersistentMessage,
    useMessagingContext,
} from "@src/contexts/MessagingContext";
import { rpc } from "@src/contexts/RpcContext";

export function ActivityMessage({ message }: { message: PersistentMessage }) {
    const { t } = useTranslation();
    const { libraryDetailsRedirect } = useRedirects();
    const { removePersistentMessage } = useMessagingContext();
    const { data: asset } = rpc.libraryAssets.get.useQuery(message.context.id, {
        enabled: message.context.type === "asset",
    });

    return (
        <ActivityItemWrapper>
            <View flexGrow={1} overflow="hidden">
                {asset && (
                    <Heading level={3} UNSAFE_style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                        <Link
                            to={libraryDetailsRedirect({
                                assetId: asset.id,
                                viewType: "2D",
                                pathOnly: true,
                            })}>
                            {asset.name}
                        </Link>
                    </Heading>
                )}
                <Flex alignItems="center">
                    <Alert color="negative" size="S" />
                    <Heading marginStart="size-75" level={4}>
                        {message.message}
                    </Heading>
                </Flex>
            </View>
            <TooltipTrigger>
                <ActionButton
                    onPress={() => removePersistentMessage(message.id)}>
                    {t("actions.dismiss")}
                </ActionButton>
                <Tooltip>{t("activity-panel.tooltip.error")}</Tooltip>
            </TooltipTrigger>
        </ActivityItemWrapper>
    );
}
