/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { PromiseHelpers } from "@shared/common";
import { ErrorCode } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { v4 } from "uuid";

import { useRedirects } from "./useRedirects";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";
import { useAppContext } from "@src/contexts/AppContext";
import { rpc } from "@src/contexts/RpcContext";
import { useUploadDownloadContext } from "@src/contexts/UploadDownloadContext";
import { MAX_UPLOAD_FILE_SIZE_IN_MB } from "@src/interfaces/library/components/UploadDialog";
import type { UploadAnalyticsBatchFields } from "@src/lib/analytics/AnalyticsConstants";
import { combineFileWithBatchFields } from "@src/lib/analytics/UploadAnalyticsUtil";
import { NotificationType } from "@src/lib/services/Notifications";
import { Upload } from "@src/lib/upload/Upload";
import { formatName } from "@src/lib/utils/filenameHelper";

import type { FileRejection } from "react-dropzone";

export const useUpload = () => {
    const { acp, ims, notificationService, logger } = useAppContext();
    const { uploadManager } = useUploadDownloadContext();
    const { logIngestEvent } = useAnalyticsContext();
    const location = useLocation();
    const {libraryDetailsRedirect} = useRedirects();

    const isDetailsPage = location.pathname.match(/\/library\/.+\/detail/)

    const upgradeAssetMutation =
        rpc.libraryAssets.uploadNewMajorVersion.useMutation();
    const cancelAssetMutation = rpc.libraryAssets.cancel.useMutation();
    const createAssetMutation = rpc.libraryAssets.create.useMutation();

    const { t } = useTranslation("library");

    function notifyRejectedFiles(rejectedFiles: FileRejection[]): void {
        rejectedFiles.forEach(({ errors, file }) => {
            errors.forEach((error) => {
                switch (error.code) {
                    case ErrorCode.FileInvalidType:
                        notificationService.addNotification({
                            type: NotificationType.Error,
                            fileName: file.name,
                            description: t("notification.upload.badFileType"),
                        });
                        break;
                    case ErrorCode.FileTooLarge:
                        notificationService.addNotification({
                            type: NotificationType.Error,
                            fileName: file.name,
                            description: t("notification.upload.fileTooLarge", {
                                maxSize: MAX_UPLOAD_FILE_SIZE_IN_MB,
                            }),
                        });
                        break;
                    default:
                        break;
                }
            });
        });
    }

    async function uploadFile(
        file: File,
        batchFields: UploadAnalyticsBatchFields,
    ) {
        const { name, size } = file;
        // Force a check for token validity. If the token is invalid, the user
        // will be redirected to IMS to sign in.
        const accessToken = await ims.getAccessToken();
        if (!accessToken) {
            // The user will be redirected, but let's get out before mucking
            // with the DB.
            throw new Error("Invalid token. Could not upload file.");
        }
        const newAsset = await createAssetMutation.mutateAsync({
            name: formatName(t, name),
            fileExtension: `.${file.name.split(".").pop()}`,
            size,
        });
        const analyticsFields = combineFileWithBatchFields(
            batchFields,
            file,
            newAsset.id ?? v4(),
        );
        logIngestEvent("uploadStart", analyticsFields);

        if (!newAsset.id || !newAsset.compositeId) {
            logIngestEvent("uploadError", analyticsFields);
            throw new Error("Failed to create new asset.");
        }

        return uploadManager
            .addUpload(
                new Upload(
                    acp,
                    file,
                    newAsset.id,
                    newAsset.compositeId,
                    newAsset.componentId,
                    logger,
                ),
            )
            .then(() => {
                logIngestEvent("uploadSuccess", analyticsFields);
            })
            .catch(() => {
                logIngestEvent("uploadError", analyticsFields);
                throw new Error(`Failed to upload ${file.name}`);
            });
    }

    async function uploadFiles(
        acceptedFiles: File[],
        batchAnalyticsFields: UploadAnalyticsBatchFields,
    ) {
        return PromiseHelpers.rejectedResults(
            await Promise.allSettled(
                acceptedFiles.map(async (file) => {
                    return uploadFile(file, batchAnalyticsFields);
                }),
            ),
        );
    }

    async function uploadNewMajorVersion(
        id: string,
        compositeId: string,
        name: string,
        localFile: File,
        batchFields: UploadAnalyticsBatchFields,
    ): Promise<string> {
        let analyticsFields = combineFileWithBatchFields(
            batchFields,
            localFile,
            v4(),
        );
        if (!(id && compositeId && localFile)) {
            logIngestEvent("uploadStart", analyticsFields);
            logIngestEvent("uploadError", analyticsFields);
            throw new Error(
                "Asset ID, composite ID, and local file are all required to upload a new version",
            );
        }
        const upgradedAsset = await upgradeAssetMutation.mutateAsync({
            predecessorId: id,
            size: localFile.size,
        });
        if (upgradedAsset.id) {
            analyticsFields = combineFileWithBatchFields(
                batchFields,
                localFile,
                upgradedAsset.id,
            );
            if (isDetailsPage) {
                libraryDetailsRedirect({
                    assetId: upgradedAsset.id,
                    viewType: "2D",
                })
            }
        }
        logIngestEvent("uploadStart", analyticsFields);
        if (!upgradedAsset) {
            logIngestEvent("uploadError", analyticsFields);
            throw new Error("Failed to create upgraded asset record");
        }
        const upload = new Upload(
            acp,
            localFile,
            upgradedAsset.id,
            compositeId,
            upgradedAsset.componentId,
            logger,
        );

        uploadManager
            .addUpload(upload)
            .then(() => {
                logIngestEvent("uploadSuccess", analyticsFields);
            })
            .catch(() => {
                logIngestEvent("uploadError", analyticsFields);
                try {
                    cancelAssetMutation.mutate(id);
                } catch (innerE) {
                    console.error(innerE);
                }
                throw new Error(`Failed to upload new version of ${name}`);
            });
        return upload.id;
    }

    function cancelUpload(uploadId: string) {
        if (!uploadId) {
            throw Error("There must be a valid upload id to cancel");
        }
        uploadManager.cancelUploads([uploadId]);
    }

    return {
        uploadFiles,
        uploadNewMajorVersion,
        notifyRejectedFiles,
        cancelUpload,
    };
};
