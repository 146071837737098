/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { EventEmitter } from "events";

import type { Upload, UploadEvents } from "./Upload";
import type { HeliosLogger } from "../services/HeliosLogger";

export interface UploadEmitEventData {
    uploadId: string;
    libraryAssetId: string;
    fileName: string;
    error: { message: string };
    progress: number;
    [key: string]: any;
}

type EventManagerEvents = {
    slowNetwork: [{speed: number}],
}

type ExtendedData<T> = T extends [infer K] ? [K & {upload: Upload}] : T;

type ExtendedEmitEvent<T extends Record<keyof T, any[]>> = {
    [K in keyof T]: ExtendedData<T[K]>
}

export type UploadEventManagerEvents = "slowNetwork";

const MIN_UPLOAD_DURATION_CHECK = 20; // 20 seconds
const MIN_UPLOAD_SPEED_BYTES_PER_SECONDS = 5 * 1024 * 1024 / 8;
export class UploadManager extends EventEmitter<EventManagerEvents | ExtendedEmitEvent<UploadEvents>> {
    uploads: Record<string, Upload> = {};
    get hasUploads() {
        return Object.keys(this.uploads).length > 0;
    }
    private _logger?: HeliosLogger;

    set logger(newLogger: HeliosLogger) {
        this._logger = newLogger;
    }

    addUpload(upload: Upload) {
        this.uploads[upload.id] = upload;
        upload.on("PROGRESS", () => this.speedCheck());
        this.handleEmittedEvents(upload);
        return upload.start();
    }

    speedCheck() {
        let totalSpeed = 0;
        for(const uploadId in this.uploads) {
            const upload = this.uploads[uploadId];
            if (upload.uploadDuration < MIN_UPLOAD_DURATION_CHECK) {
                return;
            }
            totalSpeed += upload.uploadSpeed;
        }
        if (totalSpeed < MIN_UPLOAD_SPEED_BYTES_PER_SECONDS) {
            this.emit("slowNetwork", {speed: totalSpeed});
        }
    }

    cancelUploads(uploadIds: string[]) {
        uploadIds.forEach((uploadId) => {
            const upload = this.uploads[uploadId];
            if (!upload) {
                this._logger?.logError({
                    errorCode: "2050",
                    resources: [{ uploadId }],
                });
                return;
            }
            upload.cancel();
        });
    }

    deleteUpload(uploadId: string) {
        if (!this.uploads[uploadId]) {
            return;
        }
        this.uploads[uploadId].removeAllListeners();
        delete this.uploads[uploadId];
    }

    onError(uploadId: string): void {
        this.deleteUpload(uploadId);
    }

    handleEmittedEvents(upload: Upload) {
        upload.on("STARTED", () => this.emit("STARTED", {upload}));
        upload.on("CANCELED", () => this.emit("CANCELED", {upload}));
        upload.on("ERROR", (data) => {
            this.onError(data.uploadId);
            this.emit("ERROR", {...data, upload})
        })
        upload.on("PROGRESS", (data) => this.emit("PROGRESS", {...data, upload}));
        upload.on("COMPLETED", (data) => this.emit("COMPLETED", {...data, upload}));
    }
}
