/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ShotItem } from "@src/contexts/RpcContext";

export const useShotCameraName = (shot?: ShotItem) => {
    const { t } = useTranslation(["studio", "common"]);

    const shotCameraName =  useMemo(() => {
        if (shot) {
            const {type, localeKey, camera} = shot;
            console.log(localeKey);
            if (type === "SHOT_2D") {
                return t(`studio:${localeKey}`) ?? t(`studio:${camera}`);
            }
            return t("output.web.player");
        }
        return "";
    }, [shot]);

    return shotCameraName;
};
