/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useMemo } from "react";

export function useIsMobile() {
    var userAgent = navigator.userAgent || "";
    const isAndroid = useMemo(() => /android/i.test(userAgent), [userAgent]);
    const isMobile = useMemo(() => {
        const isMacintosh = navigator.userAgent.includes("Macintosh");
        const hasFiveTouchPoints = navigator.maxTouchPoints === 5;

        return (
            /android/i.test(userAgent) ||
            (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) ||
            (isMacintosh && hasFiveTouchPoints) // Vision Pro
        );
    }, [userAgent]);

    return { isMobile, isAndroid };
}
