/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { THUMBNAIL_WIDTH, THUMBNAIL_HEIGHT, RenderQualitySamplesValue } from "./index.js";
import { isLaunchpad } from "@shared/types";

import type {
    Job,
    JobTiers,
    ModelRenderParameters,
    UsdSpec,
} from "@shared/types";

function isSmallModelRender(parameters: ModelRenderParameters): boolean {
    const resolution = parameters.resolution;
    if (resolution === undefined) {
        return false;
    }
    const width_threshold = THUMBNAIL_WIDTH;
    const height_threshold = THUMBNAIL_HEIGHT;
    const area_threshold = width_threshold * height_threshold;
    const { width, height } = resolution;
    // If either dimension is unspecified, we can't know that the render is small
    // so we'll assume it's large, just in case
    if (height === undefined || width === undefined) {
        return false;
    }
    const area = width * height;
    return area <= area_threshold;
}

function isLowQualityModelRender(parameters: ModelRenderParameters): boolean {
    const samples = parameters.samples;
    if (samples === undefined) {
        return false;
    }
    const threshold = RenderQualitySamplesValue.medium;
    return samples <= threshold;
}

class OperationCounter {
    heavy = 0;
    medium = 0;
    light = 0;
}

type SimpleJobTiers = "warm" | "cool";

function tierForOperations(operationCounts: OperationCounter): SimpleJobTiers {
    if (operationCounts.heavy === 0 && operationCounts.medium <= 2) {
        return "warm";
    }
    return "cool";
}

export function jobUsesGpu(_job: Job): boolean {
    return Object.keys(_job.spec).some(t => isLaunchpad(_job.spec[t]));
}

function countUsdOperations(nodes: UsdSpec): OperationCounter {
    const counter = new OperationCounter();
    for (const nodeId in nodes) {
        const node = nodes[nodeId];
        if (node === undefined) {
            throw new Error("node undefined");
        }
        if (isLaunchpad(node)) {
            counter.heavy += 1;
        }
        switch (node.type) {
            case "model.render": {
                const p = node?.parameters;
                const isFastRender =
                    p && isSmallModelRender(p) && isLowQualityModelRender(p);
                if (isFastRender) {
                    counter.medium += 1;
                } else {
                    counter.heavy += 1;
                }
                break;
            }
            case "sunrise.project.export.aem":
            case "model.compact": {
                counter.heavy += 1;
                break;
            }
            case "model.convert":
            case "model.normalize": {
                counter.medium += 1;
                break;
            }
            default: {
                counter.light += 1;
            }
        }
    }
    return counter;
}

export function hasRender(job: Job): boolean {
    switch (job.type) {
        case "multi.beta": {
            return (
                job.spec &&
                Object.keys(job.spec).some(
                    (k) => job.spec[k]?.type === "scene.render",
                )
            );
        }
        case "usd.beta": {
            return (
                job.spec &&
                Object.keys(job.spec).some(
                    (k) => job.spec[k]?.type === "model.render",
                )
            );
        }
    }
}

function getUsdTier(nodes: UsdSpec): SimpleJobTiers {
    const operationCounts = countUsdOperations(nodes);
    return tierForOperations(operationCounts);
}

export function getTier(job: Job): JobTiers {
    if (jobUsesGpu(job)) {
        return "warm-gpu";
    }

    switch (job.type) {
        case "multi.beta": {
            return "cool";
        }
        case "usd.beta": {
            return getUsdTier(job.spec);
        }
    }
}
