/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, Text, View } from "@adobe/react-spectrum";
import Alert from "@spectrum-icons/workflow/Alert";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ShotBadges } from "./ShotBadges";
import type { ShotListItem } from "@src/contexts/RpcContext";
import { rpc } from "@src/contexts/RpcContext";
import { DelayedSpinner } from "@src/interfaces/common/components/DelayedSpinner";
import { Thumbnail } from "@src/interfaces/common/components/Thumbnail";
import { useShotCameraName } from "@src/interfaces/common/hooks/useShotCameraName";

interface Props {
    shotListItem: ShotListItem;
}

export const OutputCard = ({ shotListItem: { id, type } }: Props) => {
    const { t } = useTranslation(["studio", "common"]);
    const { data: shot } = rpc.shots.getShot.useQuery(id);
    const { data: shotThumbnail, isError } =
        rpc.shots.getShotThumbnailForCard.useQuery(id);

    const cameraName = useShotCameraName(shot);

    const [altText, setAltText] = useState<string>("");

    useEffect(() => {
        if (shot?.type && shot?.camera) {
            setAltText(
                shot.type === "SHOT_3D"
                    ? t("studio:output.web.player")
                    : shot.camera,
            );
        } else {
            setAltText("");
        }
    }, [shot?.type, shot?.camera]);

    const thumbError = shotThumbnail?.jobFailed || isError;

    return (
        <>
            <Flex
                UNSAFE_style={{
                    position: "absolute",
                }}
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap="size-150"
                width="100%"
                height="calc(100% - 78px)">
                {thumbError ? (
                    <Alert size="L" />
                ) : (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                        }}>
                        {shotThumbnail && shotThumbnail.jobCompleted ? (
                            <Thumbnail
                                url={shotThumbnail?.url}
                                alt={altText}
                                showFallbackImage
                                checkerBoard
                            />
                        ) : (
                            <DelayedSpinner />
                        )}
                    </div>
                )}
                {shot?.id && (
                    <View
                        position="absolute"
                        top={0}
                        right={0}
                        margin="size-150">
                        <ShotBadges shotId={shot.id} errors={shot?.jobErrors} />
                    </View>
                )}
            </Flex>
            <Flex
                direction="column"
                alignItems="start"
                marginTop="size-100"
                height="size-600">
                <Heading
                    level={4}
                    alignSelf="auto"
                    UNSAFE_style={{
                        fontWeight: 500,
                    }}>
                    {cameraName}
                </Heading>
                <Text
                    UNSAFE_style={{
                        color: "gray",
                        fontSize: "12px",
                    }}>
                    {type === "SHOT_3D" ? t("3D.viewer") : t("2D.shot")}
                </Text>
            </Flex>
        </>
    );
};
