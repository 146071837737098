/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex } from "@adobe/react-spectrum";
import { HeliosRoutes } from "@shared/common";
import { ASSET_COMPOSITE_TYPE, PROJECT_COMPOSITE_TYPE } from "@shared/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useRedirects } from "../../../common/hooks/useRedirects";
import { DetailViewType } from "../common/DetailViewTabs";
import { useAppContext } from "@src/contexts/AppContext";
import { rpc } from "@src/contexts/RpcContext";
import { DelayedSpinner } from "@src/interfaces/common/components/DelayedSpinner";
import type { AccessLevelType } from "@src/lib/services/AccessService";
import { ACCESS_LEVELS } from "@src/lib/services/AccessService";
import { UnauthorizedPage } from "@src/pages/unauthorizedPage";

// For projects, we share from the project folder asset urn.
const PROJECT_SHARE_TYPE = "application/vnd.adobe.directory+json";
/**
 * This is the landing page when sharing a review link from the detail asset view or project view.
 * This is also the landing page when a comment link redirects back to Sunrise.
 * The review and commenting access level are determined in AccessService.
 * The result from AccessService determines if user has full access to the asset or project library, only limited access,
 * shared resource was no longer available, or user access is denied.
 */
interface ShareAssetReviewProps {
    accessLevel?: AccessLevelType; // determined by AccessService
    assetResourceType?: string; // determined by AccessService
}
/**
 * Landing page for the Share asset review:
 * review page url (asset) - {host}/review?urn={asset-urn}&type={"asset"}&viewType={viewType}
 * review page url (project) - {host}/review?urn={asset-urn}&type={"project"}
 * limited access url (asset) - {host}/library/review/{dataId}/{viewType}
 * limited access url (project) - {host}/projects/review/{dataId}
 * full access url (asset) - {host}/library/assets/{dataId}/{viewType}
 * full access url (project) - {host}/projects | projectsLegacy/{dataId}
 */
export const ShareAssetReview = ({
    accessLevel,
    assetResourceType,
}: ShareAssetReviewProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const { logger } = useAppContext();
    const [searchParams] = useSearchParams();
    const assetId = searchParams.get("urn") || ""; // This is either library asset or project composite id.
    const viewType = (searchParams.get("viewType") ||
        DetailViewType.views2D) as DetailViewType;
    const assetType = searchParams.get("type");
    const commentId = searchParams.get("commentId");
    const showComments = searchParams.get("showComments");

    useEffect(() => {
        if (!assetId || !(assetType || commentId)) {
            navigate(HeliosRoutes.notFound.path);
        }
    }, [assetId, assetType, commentId]);


    const { libraryDetailsRedirect, projectRedirect } = useRedirects();

    const [showNoAccess, setShowNoAccess] = useState<boolean>(false);
    const [processing, setIsProcessing] = useState<boolean>(
        accessLevel === ACCESS_LEVELS.calculating,
    );

    const { data: asset, error: assetByCompositeIdError } =
        rpc.libraryAssets.getAssetByCompositeId.useQuery(
            {
                assetId,
            },
            {
                enabled:
                    assetType === "asset" ||
                    assetResourceType === ASSET_COMPOSITE_TYPE,
            },
        );

    useEffect(() => {
        if (assetByCompositeIdError?.data?.code === "NOT_FOUND") {
            navigate(HeliosRoutes.notFound.path);
        }
    }, [assetByCompositeIdError]);

    // The invitation link has an asset urn pointing to the project's containing folder.
    const { data: project1, error: projectByFolderCompositeIdError } =
        rpc.projects.getProjectByParentFolderCompositeId.useQuery(
            {
                assetId,
            },
            {
                enabled:
                    assetType === "project" ||
                    assetResourceType === PROJECT_SHARE_TYPE,
            },
        );

    useEffect(() => {
        if (projectByFolderCompositeIdError?.data?.code === "NOT_FOUND") {
            navigate(HeliosRoutes.notFound.path);
        }
    }, [projectByFolderCompositeIdError]);

    // The commenting link references an asset urn pointing to the project composite id.
    const { data: project2, error: projectByCompositeIdError } =
        rpc.projects.getProjectByCompositeId.useQuery(
            {
                assetId,
            },
            {
                enabled:
                    assetType === "project" ||
                    assetResourceType === PROJECT_COMPOSITE_TYPE,
            },
        );
    useEffect(() => {
        if (projectByCompositeIdError?.data?.code === "NOT_FOUND") {
            navigate(HeliosRoutes.notFound.path);
        }
    }, [projectByCompositeIdError]);

    useEffect(() => {
        if (
            accessLevel !== ACCESS_LEVELS.calculating &&
            (asset || project1 || project2)
        ) {
            if (accessLevel === ACCESS_LEVELS.unauthorized) {
                // Show access denied view.
                setShowNoAccess(true);
                return;
            } else if (accessLevel === ACCESS_LEVELS.unavailable) {
                navigate(HeliosRoutes.unavailable.path);
                return;
            } else if (accessLevel === ACCESS_LEVELS.none) {
                navigate(HeliosRoutes.notFound.path);
                return;
            }

            // Once access is determined, don't allow the ability to go back to the
            // landing page.
            const fullAccess = accessLevel === ACCESS_LEVELS.full;
            setIsProcessing(false);
            if (
                asset &&
                (assetType === "asset" ||
                    assetResourceType === ASSET_COMPOSITE_TYPE)
            ) {
                libraryDetailsRedirect({
                    assetId: asset.id,
                    viewType,
                    search: {
                        commentId,
                        showComments,
                    },
                    limitedAccess: !fullAccess,
                    navigateOptions: { replace: true },
                });
            } else if (
                (project1 || project2) &&
                (assetType === "project" ||
                    assetResourceType === PROJECT_COMPOSITE_TYPE)
            ) {
                // Sanity check
                if (project2?.id && project2?.compositeId !== assetId) {
                    logger.logError({
                        errorCode: "2188",
                        resources: [{ assetId: project1?.id ?? project2?.id }],
                    });
                    throw new Error(
                        `Unexpected project id from the asset urn ${assetId} for this share for review link.`,
                    );
                }
                projectRedirect({
                    projectId: project1?.id || project2?.id,
                    search: {
                        commentId,
                        showComments,
                    },
                    limitedAccess: !fullAccess,
                    navigateOptions: { replace: true },
                });
            }
        } else if (accessLevel === ACCESS_LEVELS.none) {
            navigate(HeliosRoutes.notFound.path);
        }
    }, [accessLevel, asset, project1, project2]);

    return (
        <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%">
            {processing ? (
                <DelayedSpinner size="L" description={t("account.access")} />
            ) : (
                showNoAccess && <UnauthorizedPage />
            )}
        </Flex>
    );
};
