/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Icon, IconProps } from "@adobe/react-spectrum";

export function ArBox(props: Omit<IconProps, "children">) {
    return (
        <Icon {...props}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                <mask
                    id="mask0_63_2739"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="18"
                    height="18">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15 9.19507V10.3035C16.1041 10.909 16.7764 11.6765 16.7764 12.5001C16.7764 14.3975 13.2153 16.0001 9 16.0001C4.78467 16.0001 1.22363 14.3975 1.22363 12.5001C1.22363 11.6764 1.89593 10.909 3 10.3035V9.19507C1.2868 10.0112 0.223633 11.1789 0.223633 12.5001C0.223633 15.0235 4.07861 17.0001 9 17.0001C13.9214 17.0001 17.7764 15.0235 17.7764 12.5001C17.7764 11.1789 16.7132 10.0112 15 9.19507Z"
                        fill="#EBEBEB"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.17213 3.21911C9.02963 3.14759 8.86156 3.14836 8.71972 3.22118L4.27148 5.50487C4.26721 5.50707 4.24731 5.51811 4.24023 5.52245C4.0913 5.61278 4 5.77489 4 5.9497V11.4019C4 11.5732 4.08776 11.7326 4.23255 11.8243L8.73242 14.6729C8.81396 14.7236 8.90674 14.75 9 14.75C9.08301 14.75 9.16602 14.7295 9.24121 14.6885C9.24615 14.6857 9.24817 14.6799 9.25299 14.6771C9.25751 14.6745 9.26312 14.6756 9.26758 14.6729L13.7675 11.8243C13.9122 11.7327 14 11.5732 14 11.4019V5.95069C14 5.76158 13.8933 5.58864 13.7243 5.50381L9.17213 3.21911ZM8.95068 4.22655L12.4006 5.95738L9 7.72447L5.58966 5.95231L8.95068 4.22655ZM5 6.77294L8.5 8.59179V13.3418L5 11.1265V6.77294ZM13 11.1265L9.5 13.3418V8.59178L13 6.77293V11.1265Z"
                        fill="#EBEBEB"
                    />
                </mask>
                <g mask="url(#mask0_63_2739)">
                    <rect width="20" height="20" fill="white" />
                </g>
            </svg>
        </Icon>
    );
}
