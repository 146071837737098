/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Content, Dialog, Divider, Heading } from "@adobe/react-spectrum";
import { JobsApiConfig, getCompletedJob } from "@shared/client";
import { openapi } from "@shared/types";
import { useContext, useRef } from "react";

import { config } from "@src/config";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import { rpc } from "@src/contexts/RpcContext";
import { ThemeContext } from "@src/contexts/ThemeContext";

const apiConfig: JobsApiConfig = {
    url: `${config.hostApi}/v1`,
    key: config.imsClientId,
}

export function useSendTo(accessToken: string) {
    const { client } = rpc.useUtils();
    const { showModal, closeModal } = useMessagingContext();
    const { isDarkMode } = useContext(ThemeContext);
    const tokenRef = useRef("");

    async function sendToAem(
        projectId: string,
        projectName: string,
    ) {
        return new Promise(async (resolve, rej) => {
            const {
                DestinationSelectorWithAuthFlow,
                registerAssetsSelectorsAuthService,
            } = await import(
                // @ts-ignore
                "https://experience.adobe.com/solutions/CQ-assets-selectors/static-assets/resources/@assets/selectors/index.js"
            );

            const configs = await client.orgs.getConfigs.query();

            if (!configs.aemConfig) {
                throw new Error("Could not load AEM config");
            }

            const {
                imsClientId,
                imsOrg,
                imsScope,
                aemOrgName,
                aemRepoId,
                aemRootPath,
            } = configs.aemConfig;

            const initImsAuthInfo = {
                env: "prod",
                imsClientId,
                imsOrg,
                imsScope,
                redirectUrl: window.location.href,
                imsAuthService: undefined,
                onAccessTokenReceived: (tokenRes: string | undefined | {token: string}) => {
                    if (typeof tokenRes === "string") {
                        tokenRef.current = tokenRes
                    } else if (tokenRes?.token) {
                        tokenRef.current = tokenRes.token;
                    } else if (tokenRes === undefined) {
                        tokenRef.current = "";
                    }
                }
            };

            const imsAuthService =
                registerAssetsSelectorsAuthService(initImsAuthInfo);

            const imsAuthInfo = {
                ...initImsAuthInfo,
                imsAuthService,
            };

            const projectDownloadInfo =
                await client.projects.getProjectDownloadInfo.query(projectId);

            async function sendData(destination: any) {
                const parameters: openapi["sunrise.project.export.aem"]["parameters"] = {
                    token: tokenRef.current,
                    destination,
                    projectName,
                    assets: [
                            ...projectDownloadInfo.renders
                                .filter((render) => !!render.url)
                                .map((render) => ({
                                    type: "render" as any,
                                    renderType: render.isPreview
                                        ? "preview"
                                        : "full",
                                    sunriseUrl: render.url as string,
                                    sunriseAssetId: render.libraryAssetId,
                                    name: render.libraryAssetName,
                                    camera: render.camera,
                                    ext: ".png",
                                })),
                            ...projectDownloadInfo.models
                                .filter((model) => !!model.url)
                                .map((model) => ({
                                    type: "model" as any,
                                    modelType: model.type as any,
                                    hotspotData: model.hotspotData,
                                    sunriseUrl: model.url as string,
                                    sunriseAssetId: model.libraryAssetId,
                                    name: model.libraryAssetName,
                                    ext: model.fileExtension,
                                })),
                    ],
                };

                const state = await getCompletedJob(accessToken, {
                    type: "usd.beta",
                    spec: {
                        sendToAem: {
                            type: "sunrise.project.export.aem",
                            parameters,
                            output: {
                                type: "json",
                                extension: ".json",
                            }
                        },
                        storeResult: {
                            type: "base64.result",
                            inputs: {
                                file: "sendToAem",
                            },
                            parameters: {
                                key: 'sendToAem',
                            },
                        }
                    }
                }, apiConfig)

                if (state === "COMPLETED") {
                    resolve({success: true});
                } else {
                    rej(new Error(`status ${state}`));
                }
            }

            showModal(
                <Dialog width="max-content" height="max-content">
                    <Heading>Select AEM Destination</Heading>
                    <Divider />
                    <Content
                        UNSAFE_style={{
                            overflow: "hidden",
                            height: "100%",
                            width: "100%",
                            position: "relative",
                        }}>
                        <DestinationSelectorWithAuthFlow
                            {...imsAuthInfo}
                            env="PROD"
                            orgName={aemOrgName}
                            intl={{ locale: "en-us" }}
                            initRepoId={aemRepoId}
                            rootPath={aemRootPath}
                            colorScheme={isDarkMode ? "dark" : "light"}
                            onClose={() => {
                                closeModal();
                                rej();
                            }}
                            onConfirm={(selectedDestination: any) => {
                                sendData(selectedDestination);
                                closeModal();
                            }}
                        />
                    </Content>
                </Dialog>,
            );
        });
    }

    return { sendToAem };
}
