/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useAppContext } from "./AppContext";
import { useFeatureFlagContext } from "./FeatureFlagContext";
import { useMessagingContext } from "./MessagingContext";
import { rpc } from "./RpcContext";
import { Upload } from "@src/lib/upload/Upload";
import { UploadManager } from "@src/lib/upload/UploadManager";
import { fileExtension } from "@src/lib/utils/filenameHelper";

import type { TRPCClientError } from "@trpc/client";
import type { AnyRouter } from "@trpc/server";

const uploadManager = new UploadManager();

export const uploadDownloadContext = {
    uploadManager,
    uploading: false,
    addDownload: (name: string) => name,
    removeDownload: (id: string) => {
        if (id){
            throw new Error(`This should never get called ${id}`)
        }
    },
    downloading: false,
};

export const UploadDownloadContext = createContext<
    typeof uploadDownloadContext
>(uploadDownloadContext);

let slowMessageShown = false;

export const UploadDownloadContextProvider = ({
    children,
}: React.PropsWithChildren) => {
    const { t } = useTranslation(["common", "library"]);
    const { showToastForInvalidPermissions, error, warn } =
        useMessagingContext();

    const addUploadIdMutation = rpc.libraryAssets.addUploadId.useMutation();
    const updateUploadProgressMutation =
        rpc.libraryAssets.updateUploadProgress.useMutation();
    const uploadCompleteMutation =
        rpc.libraryAssets.uploadSourceComplete.useMutation();
    const uploadErrorsMutation =
        rpc.libraryAssets.addUploadErrors.useMutation();
    const uploadCancelMutation = rpc.libraryAssets.cancel.useMutation();

    const [uploading, setUploading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const [activeDownloads, setActiveDownloads] = useState<
        { id: string; name: string }[]
    >([]);

    function addDownload(name: string) {
        const id = window.crypto.randomUUID();
        setActiveDownloads((downloads) => {
            return [{ id, name }, ...downloads];
        });
        return id;
    }

    function removeDownload(id: string) {
        setActiveDownloads((downloads) => {
            return downloads.filter((download) => download.id !== id);
        });
    }

    useEffect(() => {
        setDownloading(!!activeDownloads.length);
    }, [activeDownloads]);

    useEffect(() => {
        if (uploading || downloading) {
            window.onbeforeunload = () => confirm();
        } else {
            window.onbeforeunload = null;
        }
    }, [uploading, downloading]);

    const { logger } = useAppContext();
    uploadManager.logger = logger;

    const { FF_SKIP_ONEAPI } = useFeatureFlagContext();

    function handleUploadStarted(upload: Upload) {
        setUploading(true);
        addUploadIdMutation.mutate({
            id: upload.libraryAssetId,
            uploadId: upload.id,
        });
    }

    async function handleUploadProgress(upload: Upload, progress: number) {
        if (upload.libraryAssetId && progress > 0) {
            try {
                await updateUploadProgressMutation.mutateAsync({
                    id: upload.libraryAssetId,
                    progress: progress,
                });
            } catch (err) {
                logger.logError({
                    errorCode: "2052",
                    resources: [
                        {
                            assetId: upload.libraryAssetId,
                            uploadId: upload.id,
                        },
                    ],
                });
            }
        }
    }

    function cleanUpUpload(uploadId: string) {
        if (!uploadId) {
            throw Error("There must be a valid upload id to clean up");
        }
        uploadManager.deleteUpload(uploadId);
        setUploading(uploadManager.hasUploads);
    }

    const handleUploadCompleted = useCallback(
        async (upload: Upload, version = "1") => {
            if (upload.libraryAssetId) {
                try {
                    await uploadCompleteMutation.mutateAsync({
                        id: upload.libraryAssetId,
                        sourceModelVersion: version,
                        fileExtension: fileExtension(upload.fileName, ".usdz"),
                        skipOneApi: FF_SKIP_ONEAPI,
                    });
                } catch (e) {
                    error(
                        t("library:notification.upload.error", {
                            filename: upload.fileName,
                            interpolation: { escapeValue: false },
                        }),
                    );
                }
            }
            cleanUpUpload(upload.id);
        },
        [FF_SKIP_ONEAPI],
    );

    function handleUploadCanceled(upload: Upload) {
        uploadCancelMutation.mutateAsync(upload.libraryAssetId).catch((e) => {
            if ((e as TRPCClientError<AnyRouter>)?.data?.code === "FORBIDDEN") {
                showToastForInvalidPermissions();
            } else {
                throw e;
            }
        });
        cleanUpUpload(upload.id);
    }

    function handleUploadError(uploadData: Upload, err: unknown) {
        error(
            t("library:notification.upload.error", {
                filename: uploadData.fileName,
                interpolation: { escapeValue: false },
            }),
            undefined,
            { type: "file", id: uploadData.fileName },
        );
        cleanUpUpload(uploadData.id);
        uploadErrorsMutation.mutate({
            id: uploadData.libraryAssetId,
            uploadErrors: [err as { message: string }],
        });
    }

    function handleSlowUploads(speed: number) {
        if (!slowMessageShown) {
            slowMessageShown = true;
            console.warn("Slow network", { speed });
            warn(t("toast.slowNetwork"), {
                onAction() {
                    window
                        .open(
                            "https://helpx.adobe.com/sunrise/assets/upload-assets.html#h3",
                            "_blank",
                        )
                        ?.focus();
                },
                actionLabel: t("toast.slowNetwork.action"),
            });
        }
    }
    // @ts-ignore
    window.testSlowNetworkMessage = handleSlowUploads;

    useEffect(() => {
        uploadManager.on("STARTED", ({ upload }) =>
            handleUploadStarted(upload),
        );
        uploadManager.on("PROGRESS", ({ upload, progress }) =>
            handleUploadProgress(upload, progress),
        );
        uploadManager.on("COMPLETED", ({ upload, uploadResult }) =>
            handleUploadCompleted(upload, uploadResult.version),
        );
        uploadManager.on("CANCELED", ({ upload }) =>
            handleUploadCanceled(upload),
        );
        uploadManager.on("ERROR", ({ upload, error }) =>
            handleUploadError(upload, error),
        );
        uploadManager.on("slowNetwork", ({ speed }) =>
            handleSlowUploads(speed),
        );

        return () => {
            uploadManager.removeAllListeners();
        };
    }, [handleUploadCompleted]);

    return (
        <UploadDownloadContext.Provider
            value={{
                uploadManager,
                uploading,
                addDownload,
                removeDownload,
                downloading,
            }}>
            {children}
        </UploadDownloadContext.Provider>
    );
};

export const useUploadDownloadContext = () => {
    const context = useContext(UploadDownloadContext);
    if (!context) {
        throw new Error(
            "useUploadContext must be used within a UploadContextProvider.",
        );
    }
    return context;
};
