/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { HeliosRoutes } from "@shared/common";
import { Route, Routes } from "react-router-dom";

import { useAccessContext } from "./contexts/AccessContext";
import { ArViewer } from "./interfaces/arViewer/ArViewer";
import { usePortal } from "./interfaces/common/hooks/usePortal";
import { AssetDetailView } from "./interfaces/detail/components/assets/AssetDetailView";
import { ProjectDetailReview } from "./interfaces/detail/components/projects/ProjectDetailReview";
import { ProjectOutputDetailView } from "./interfaces/detail/components/projects/ProjectOutputDetailView";
import { DetailAssetReview } from "./interfaces/detail/components/share/DetailAssetReview";
import { ShareAssetReview } from "./interfaces/detail/components/share/ShareAssetReview";
import { ProjectLibraryReview } from "./interfaces/projects/ProjectLibraryReview";
import { ACCESS_LEVELS } from "./lib/services/AccessService";
import { Config } from "./pages/config";
import { EditorPage } from "./pages/editor";
import { LibraryPage } from "./pages/library";
import { NotFoundPage } from "./pages/notFound";
import { ProjectLibraryPage } from "./pages/projectLibrary";
import { ServiceUnavailablePage } from "./pages/serviceUnavailablePage";
import { StudioStandAlone } from "./pages/studio-stand-alone";
import { UnauthorizedPage } from "./pages/unauthorizedPage";

interface Props {
    AppBarPortal: ReturnType<typeof usePortal>["Portal"];
}

export function AppRoutes({ AppBarPortal }: Props) {
    const { accessLevel, assetResourceType } = useAccessContext();

    return (
        <Routes>
            <Route
                path={HeliosRoutes.unauthorize.path}
                element={<UnauthorizedPage />}
            />
            <Route
                path={HeliosRoutes.unavailable.path}
                element={<ServiceUnavailablePage />}
            />
            <Route
                path={HeliosRoutes.shareAssetLanding.path}
                element={
                    <ShareAssetReview
                        accessLevel={accessLevel}
                        assetResourceType={assetResourceType}
                    />
                }
            />
            <Route
                path={HeliosRoutes.limitedAssetReview.path}
                element={<DetailAssetReview AppBarPortal={AppBarPortal} />}
            />
            <Route
                path={HeliosRoutes.limitedProjectReview.path}
                element={<ProjectLibraryReview />}
            />
            <Route
                path={HeliosRoutes.limitedProjectDetailReview.path}
                element={<ProjectDetailReview AppBarPortal={AppBarPortal} />}
            />
            {accessLevel === ACCESS_LEVELS.full && (
                <>
                    <Route
                        path={HeliosRoutes.config.path}
                        element={<Config />}
                    />
                    <Route
                        path={HeliosRoutes.library.path}
                        element={<LibraryPage />}
                    />
                    <Route
                        path={HeliosRoutes.search.path}
                        element={<LibraryPage />}
                    />
                    <Route
                        path={HeliosRoutes.searchLanding.path}
                        element={<LibraryPage />}
                    />
                    <Route
                        path={HeliosRoutes.libraryDetailView.path}
                        element={
                            <AssetDetailView AppBarPortal={AppBarPortal} />
                        }
                    />
                    <Route
                        path={HeliosRoutes.projectOutputDetailView.path}
                        element={
                            <ProjectOutputDetailView
                                AppBarPortal={AppBarPortal}
                            />
                        }
                    />
                    <Route
                        path={HeliosRoutes.arViewer.path}
                        element={<ArViewer />}
                    />
                    <Route
                        path={HeliosRoutes.projects.path}
                        element={<ProjectLibraryPage />}
                    />

                    <Route
                        path={HeliosRoutes.modelEditor.path}
                        element={<EditorPage AppBarPortal={AppBarPortal} />}
                    />
                    <Route
                        path={HeliosRoutes.modelEditorPreview.path}
                        element={
                            <EditorPage
                                isPreviewMode={true}
                                AppBarPortal={AppBarPortal}
                            />
                        }
                    />
                    <Route
                        path={HeliosRoutes.studioStandAlone.path}
                        element={<StudioStandAlone />}
                    />
                </>
            )}
            <Route path={"*"} element={<NotFoundPage />} />
        </Routes>
    );
}
