/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Checkbox,
    Flex,
    Form,
    Heading,
    TextField,
    View,
} from "@adobe/react-spectrum";
import { useEffect, useRef, useState } from "react";

import { useMessagingContext } from "@src/contexts/MessagingContext";
import {
    AemConfigInput,
    WorkfrontConfigInput,
    rpc,
} from "@src/contexts/RpcContext";

import type { DOMRefValue } from "@react-types/shared";

const imsClientIdDefault = "cm-p115476-e1135027-integration-1";
const imsOrgDefault = "28260E2056581D3B7F000101@AdobeOrg";
const imsScopeDefault =
    "additional_info.projectedProductContext,openid,read_organizations,AdobeID,read_pc.dma_aem_ams, exchange_jwt";
const aemOrgNameDefault = "ags050";
const aemRepoIdDefault = "author-p115476-e1135027.adobeaemcloud.com";
const aemRootPathDefault = "/content/dam";

const workfrontInstanceUrlDefault =
    "https://ags050-19032401.testdrive.workfront.com";

export function Config() {
    const { success, error } = useMessagingContext();
    const saveAemConfig = rpc.orgs.setAemConfig.useMutation();
    const saveWorkfrontConfig = rpc.orgs.setWorkfrontConfig.useMutation();
    const disableAemConfig = rpc.orgs.disableAemConfig.useMutation();
    const disableWorkfrontConfig =
        rpc.orgs.disableWorkfrontConfig.useMutation();

    const [aemConfigEnabled, setAemConfigEnabled] = useState(false);
    const [workfrontConfigEnabled, setWorkfrontConfigEnabled] = useState(false);

    const aemFormRef = useRef<DOMRefValue<HTMLFormElement>>(null);
    const wfFormRef = useRef<DOMRefValue<HTMLFormElement>>(null);

    const { data: configs, refetch } = rpc.orgs.getConfigs.useQuery();

    async function onSubmit() {
        try {
            if (aemConfigEnabled) {
                const aemData = Object.fromEntries(
                    new FormData(aemFormRef.current?.UNSAFE_getDOMNode()),
                ) as AemConfigInput;
                await saveAemConfig.mutateAsync({
                    ...aemData,
                });
            } else if (!aemConfigEnabled && configs?.aemConfig?.enabled) {
                await disableAemConfig.mutateAsync();
            }

            if (workfrontConfigEnabled) {
                const workfrontData = Object.fromEntries(
                    new FormData(wfFormRef.current?.UNSAFE_getDOMNode()),
                ) as WorkfrontConfigInput;
                await saveWorkfrontConfig.mutateAsync({
                    ...workfrontData,
                });
            } else  if (!workfrontConfigEnabled && configs?.workfrontConfig?.enabled) {
                await disableWorkfrontConfig.mutateAsync();
            }

            refetch();

            success("Configuration saved");
        } catch (e) {
            error("Could not save config");
        }
    }

    useEffect(() => {
        if (configs?.aemConfig?.enabled && !aemConfigEnabled) {
            setAemConfigEnabled(true);
        }
        if (configs?.workfrontConfig?.enabled && !workfrontConfigEnabled) {
            setWorkfrontConfigEnabled(true);
        }
    }, [configs]);

    return (
        <View height="100%" paddingStart="size-600" width="100%">
            <Heading level={1}>Configure Organization</Heading>
            <p>If you don't know what this is don't touch anything</p>
            <Flex gap="size-600" marginTop="size-600">
                <Flex direction="column">
                    <Heading level={2}>AEM Config</Heading>
                    <Checkbox
                        onChange={setAemConfigEnabled}
                        isSelected={aemConfigEnabled}>
                        Enable AEM as a project target
                    </Checkbox>
                    <Form
                        validationBehavior="native"
                        ref={aemFormRef}
                        minWidth="size-5000">
                        <TextField
                            isRequired
                            isDisabled={!aemConfigEnabled}
                            name="imsClientId"
                            label="imsClientId"
                            defaultValue={
                                configs?.aemConfig?.imsClientId ||
                                imsClientIdDefault
                            }
                        />
                        <TextField
                            isRequired
                            isDisabled={!aemConfigEnabled}
                            name="imsOrg"
                            label="imsOrg"
                            defaultValue={
                                configs?.aemConfig?.imsOrg || imsOrgDefault
                            }
                        />
                        <TextField
                            isRequired
                            isDisabled={!aemConfigEnabled}
                            name="imsScope"
                            label="imsScope"
                            defaultValue={
                                configs?.aemConfig?.imsScope || imsScopeDefault
                            }
                        />
                        <TextField
                            isRequired
                            isDisabled={!aemConfigEnabled}
                            name="aemOrgName"
                            label="aemOrgName"
                            defaultValue={
                                configs?.aemConfig?.aemOrgName ||
                                aemOrgNameDefault
                            }
                        />
                        <TextField
                            isRequired
                            isDisabled={!aemConfigEnabled}
                            name="aemRepoId"
                            label="aemRepoId"
                            defaultValue={
                                configs?.aemConfig?.aemRepoId ||
                                aemRepoIdDefault
                            }
                        />
                        <TextField
                            isRequired
                            isDisabled={!aemConfigEnabled}
                            name="aemRootPath"
                            label="aemRootPath"
                            defaultValue={
                                configs?.aemConfig?.aemRootPath ||
                                aemRootPathDefault
                            }
                        />
                    </Form>
                </Flex>
                <Flex direction="column">
                    <Heading level={2}>Workfront Config</Heading>
                    <Checkbox
                        onChange={setWorkfrontConfigEnabled}
                        isSelected={workfrontConfigEnabled}>
                        Enable Workfront panel integration
                    </Checkbox>
                    <Form
                        validationBehavior="native"
                        ref={wfFormRef}
                        minWidth="size-5000">
                        <TextField
                            isRequired
                            isDisabled={!workfrontConfigEnabled}
                            name="imsClientId"
                            label="imsClientId"
                            defaultValue={
                                configs?.workfrontConfig?.imsClientId ||
                                imsClientIdDefault
                            }
                        />
                        <TextField
                            isRequired
                            isDisabled={!workfrontConfigEnabled}
                            name="imsOrg"
                            label="imsOrg"
                            defaultValue={
                                configs?.workfrontConfig?.imsOrg ||
                                imsOrgDefault
                            }
                        />
                        <TextField
                            isRequired
                            isDisabled={!workfrontConfigEnabled}
                            name="imsScope"
                            label="imsScope"
                            defaultValue={
                                configs?.workfrontConfig?.imsScope ||
                                imsScopeDefault
                            }
                        />
                        <TextField
                            isRequired
                            isDisabled={!workfrontConfigEnabled}
                            name="wfInstanceUrl"
                            label="wfInstanceUrl"
                            defaultValue={
                                configs?.workfrontConfig?.wfInstanceUrl ||
                                workfrontInstanceUrlDefault
                            }
                        />
                    </Form>
                </Flex>
            </Flex>
            <ButtonGroup marginTop="size-500">
                <Button variant="primary" onPress={onSubmit}>
                    Submit
                </Button>
            </ButtonGroup>
        </View>
    );
}
