/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Heading,
    Tooltip,
    TooltipTrigger,
    View,
} from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { ActivityItemWrapper } from "./ActivityItemWrapper";
import { AssetProgressBar } from "./AssetProgressBars";
import { useRedirects } from "../hooks/useRedirects";
import { useUpload } from "../hooks/useUpload";
import { rpc } from "@src/contexts/RpcContext";

export function ActivityAssetProgress({ assetId }: { assetId: string }) {
    const { t } = useTranslation();
    const { cancelUpload } = useUpload();
    const { data: asset } = rpc.libraryAssets.getCardItem.useQuery(assetId);
    const cancelAsset = rpc.libraryAssets.cancel.useMutation();
    const { assetsLibraryRedirect, libraryDetailsRedirect } = useRedirects();

    const { pathname } = useLocation();

    return (
        <ActivityItemWrapper>
            <View flexGrow={1} overflow="hidden">
                {asset && (
                    <Heading level={3}  UNSAFE_style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                        <Link to={libraryDetailsRedirect({
                            assetId,
                            viewType: "2D",
                            pathOnly: true,
                        })}>
                            {asset.name}
                        </Link>
                    </Heading>
                )}
                <AssetProgressBar asset={asset} disableStatusLight />
            </View>
            <TooltipTrigger>
                <ActionButton
                    onPress={() => {
                        if (!asset?.uploadComplete && asset?.uploadId) {
                            cancelUpload(asset?.uploadId);
                        }
                        if (!asset?.jobsCompleted || !asset?.uploadComplete) {
                            cancelAsset.mutateAsync(assetId);
                        }
                        if (pathname.includes(`assets/${assetId}/detail`)) {
                            assetsLibraryRedirect();
                        }
                    }}>
                    {t("actions.cancel")}
                </ActionButton>
                <Tooltip>
                    {asset?.uploadComplete
                        ? t("activity-panel.tooltip.cancel-jobs")
                        : t("activity-panel.tooltip.cancel-upload")}
                </Tooltip>
            </TooltipTrigger>
        </ActivityItemWrapper>
    );
}
