/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useRef } from "react";

import { useMessagingContext } from "@src/contexts/MessagingContext";
import { rpc } from "@src/contexts/RpcContext";

export function useRecentlyFailedAssets() {
    const processingAssets = useRef<Record<string, boolean>>({});

    const { data: assets } = rpc.libraryAssets.getAllListPaged.useQuery({
        filters: ["processing", "myAssets"],
        count: 100,
    });
    const { libraryAssets } = rpc.useUtils();

    const { addPersistentMessage } = useMessagingContext();

    useEffect(() => {
        if (assets?.items) {
            const currentAssets = assets?.items?.reduce(
                (acc, asset) => {
                    acc[asset.id] = true;
                    return acc;
                },
                {} as Record<string, boolean>,
            );
            Object.keys(processingAssets.current).forEach((assetId) => {
                if (!currentAssets[assetId]) {
                    libraryAssets.get.fetch(assetId).then((data) => {
                        if (data?.uploadComplete && !data.jobsFailed && !data.hasValidationErrors) {
                            return;
                        }
                        data?.jobErrors.forEach((error) =>
                            addPersistentMessage(
                                error.message,
                                { id: assetId, type: "asset" },
                                "error",
                            ),
                        );
                        data?.uploadErrors.forEach((error) => {
                            addPersistentMessage(
                                error.message,
                                { id: assetId, type: "asset" },
                                "error",
                            );
                        });
                        data?.validationErrors.forEach((error) => {
                            addPersistentMessage(
                                error,
                                { id: assetId, type: "asset" },
                                "error",
                            );
                        })
                    });
                }
            });
            processingAssets.current = currentAssets;
        }
    }, [assets]);
}
