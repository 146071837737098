/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { SnacComponentPaths } from "@shared/types";
import EventEmitter from "events";

import type { ACP } from "@src/lib/services/ACP";

import type { HeliosLogger } from "../services/HeliosLogger";
import type { AdobeDCXComponent, AdobeDCXError } from "@dcx/common-types";

type UploadStatus =
"NOT_STARTED" |
"RUNNING" |
"CANCELED" |
"ERROR" |
"COMPLETED";
export interface UploadEvents {
    STARTED: [{}]
    PROGRESS: [{
        uploadId: string
        libraryAssetId: string
        progress: number
    }]
    COMPLETED: [{uploadResult: AdobeDCXComponent}]
    CANCELED: [{}]
    ERROR: [{uploadId: string, error: unknown}]
}

export class Upload extends EventEmitter<UploadEvents> {
    private acp: ACP;
    private cancelFunc?: (reason?: unknown) => void;
    private logger: HeliosLogger;

    readonly id: string;
    status: UploadStatus;
    fileName: string;
    file: File;
    libraryAssetId: string;
    compositeId: string;
    componentId: string;
    uploadDuration = 0;
    uploadSpeed = 0;


    constructor(
        acp: ACP,
        file: File,
        libraryAssetId: string,
        compositeId: string,
        componentId: string,
        logger: HeliosLogger,
    ) {
        super();
        this.acp = acp;
        this.id = crypto.randomUUID();
        this.status = "NOT_STARTED";
        this.file = file;
        this.fileName = file.name;
        this.libraryAssetId = libraryAssetId;
        this.compositeId = compositeId;
        this.componentId = componentId;
        this.logger = logger;
    }

    async start() {
        let startTime: number | undefined
        const progressCallback = (
            bytesCompleted: number,
            totalBytes: number,
        ) => {
            if (!startTime) {
                startTime = Date.now() / 1000;
            }

            this.uploadDuration = (Date.now() / 1000) - startTime;
            this.uploadSpeed = bytesCompleted / this.uploadDuration;
            this.emit("PROGRESS", {
                uploadId: this.id,
                libraryAssetId: this.libraryAssetId,
                progress: (bytesCompleted / totalBytes) * 100,
            });
        };
        this.emit("STARTED", {});
        try {
            this.status = "RUNNING";
            const { uploadComponentPromise, cancelFunc } =
                await this.acp.uploadComponent(
                    this.compositeId,
                    this.file,
                    SnacComponentPaths.sourceModel,
                    this
                        .componentId as `${string}-${string}-${string}-${string}-${string}`,
                    progressCallback,
                );
            this.logger.logInfo({
                resources: [
                    {
                        uploadId: this.id,
                        compositeId: this.compositeId,
                        componentId: this.componentId,
                        dbId: this.libraryAssetId,
                        dbTable: "LibraryAsset",
                    },
                ],
                infoCode: "10000",
            });
            this.cancelFunc = cancelFunc;

            const uploadResult = await uploadComponentPromise;
            this.emit("COMPLETED", { uploadResult });
            this.logger.logInfo({
                resources: [
                    {
                        uploadId: this.id,
                        compositeId: this.compositeId,
                        componentId: this.componentId,
                        dbId: this.libraryAssetId,
                        dbTable: "LibraryAsset",
                    },
                ],
                infoCode: "10009",
            });
        } catch (error: unknown) {
            if (
                (error as AdobeDCXError).message === "Aborted" &&
                this.status === "CANCELED"
            ) {
                // Canceled
                return;
            }
            this.logger.logError({
                errorCode: "2051",
                resources: [
                    {
                        uploadId: this.id,
                        compositeId: this.compositeId,
                        componentId: this.componentId,
                        dbId: this.libraryAssetId,
                        dbTable: "LibraryAsset",
                    },
                ],
                statusCode: (error as AdobeDCXError)?.response?.statusCode,
            });
            this.emit("ERROR", { uploadId: this.id, error });
        }
        return;
    }

    async cancel() {
        this.status = "CANCELED";
        if (this.cancelFunc) {
            this.cancelFunc();
        }
        this.emit("CANCELED", {});
    }
}
