/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Flex,
    ProgressCircle,
    Text,
} from "@adobe/react-spectrum";
import { Studio, StudioTool } from "@components/studio";
import {
    constructIblPath,
    getRenderSettings,
    projectTemplates,
} from "@shared/common";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    generatePath,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";

import { EditorNav } from "./EditorNav";
import { useHotSpotCache } from "./hooks/hotSpotCache";
import { useRedirects } from "../common/hooks/useRedirects";
import { useShotCameraName } from "../common/hooks/useShotCameraName";
import { useStudioStrings } from "../common/hooks/useStudioStrings";
import type { DefaultPageProps } from "@src/app";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import { rpc } from "@src/contexts/RpcContext";
import { ViewerError } from "@src/interfaces/common/components/ViewerError";
import { Routes } from "@src/routes";

import type {
    CameraOverride,
    HotSpot,
    RenderSettings,
    TemplateEntry,
} from "@shared/types";
import type { TRPCClientError } from "@trpc/client";
import type { AnyRouter } from "@trpc/server";

enum PreviewMode {
    enter = "enter",
    exit = "exit",
}

interface Props extends DefaultPageProps {
    isPreviewMode?: boolean;
}

export function Editor({ AppBarPortal, isPreviewMode }: Props) {
    const { projectId, shotId } = useParams();
    const location = useLocation();
    const hotSpotsUnchanged =
        location.state === PreviewMode.enter ||
        location.state === PreviewMode.exit;

    if (!shotId) {
        throw new Error(`Cannot start Editor without shotId ${shotId}`);
    }

    const { t } = useTranslation(["editor", "studio"]);
    const { showToastForInvalidPermissions } = useMessagingContext();
    const { data: shot } = rpc.shots.getShot.useQuery(shotId);
    const { data: shotModel } = rpc.shots.getShotModel.useQuery(shotId);
    const updateHotSpotsMutation = rpc.shots.updateShotHotSpots.useMutation();
    const overridesMutation = rpc.shots.updateShotOverrides.useMutation();
    const { hotSpots, initializeHotSpots, updateHotSpots, clearSavedSpots } =
        useHotSpotCache(shotId);
    const [cameraOverride, setCameraOverride] = useState<
        CameraOverride | undefined
    >();
    const canEnterPreviewMode = shot?.type === "SHOT_3D";

    const navigate = useNavigate();
    const { goBackOr, projectRedirect } = useRedirects();
    const { logIngestEvent } = useAnalyticsContext();

    const editorTools = [StudioTool.cameraControls, StudioTool.frameButton];

    const is3D = shot?.type === "SHOT_3D";
    if (is3D) {
        editorTools.push(StudioTool.hotSpotsEditor);
    }
    const shotName = useShotCameraName(shot);

    useEffect(() => {
        if (!projectId) {
            return;
        }
        const subtype = is3D ? "3d-web-experience" : "2d-shot";
        logIngestEvent("editProjectOpen", {
            "event.context_guid": projectId,
            "event.subtype": subtype,
        });
    }, [projectId]);

    const renderSettings: RenderSettings = useMemo(
        () =>
            getRenderSettings(
                projectTemplates[
                    shot?.template as keyof typeof projectTemplates
                ] as TemplateEntry,
                shot?.camera,
            ),
        [shot?.template, shot?.camera],
    );

    const iblFilename: string = useMemo(
        () =>
            (
                projectTemplates[
                    shot?.template as keyof typeof projectTemplates
                ] as TemplateEntry
            ).iblFilename,
        [shot?.template],
    );

    useEffect(() => {
        if (shot) {
            initializeHotSpots(
                hotSpotsUnchanged ? undefined : shot.hotSpots || [],
            );
        }
    }, [shot, isPreviewMode]);

    const backToProject = () => {
        clearSavedSpots();
        goBackOr(() => {
            if (projectId) {
                projectRedirect({
                    projectId,
                    navigateOptions: {},
                });
            }
        });
    };

    const save = async () => {
        if (shot) {
            const { id } = shot;
            if (shot.type === "SHOT_3D") {
                await updateHotSpotsMutation.mutateAsync({ hotSpots, id });
                logIngestEvent("editProjectSave", {
                    "event.context_guid": projectId,
                    "event.subtype": "3d-web-experience",
                    "3di.measures": {
                        num_hotspots: ((hotSpots as HotSpot[]) ?? []).length,
                    },
                });
            } else if (cameraOverride) {
                await overridesMutation
                    .mutateAsync({
                        overrides: {
                            camera: cameraOverride,
                        },
                        id,
                    })
                    .then(() => {
                        logIngestEvent("editProjectSave", {
                            "event.context_guid": projectId,
                            "event.subtype": "2d-shot",
                        });
                    })
                    .catch((e) => {
                        if (
                            (e as TRPCClientError<AnyRouter>)?.data?.code ===
                            "FORBIDDEN"
                        ) {
                            showToastForInvalidPermissions(true);
                        } else {
                            throw e;
                        }
                    });
            }
        }
        backToProject();
    };

    const editorStrings = useStudioStrings();

    if (shot && !shotModel) {
        return (
            <Flex
                direction="column"
                width="100%"
                height="100%"
                alignItems="center"
                justifyContent="center">
                <ProgressCircle
                    aria-label={t("progress.loading")}
                    isIndeterminate
                    size="L"
                />
            </Flex>
        );
    }

    function renderPreviewButton() {
        return !isPreviewMode ? (
            <ActionButton
                onPress={() => {
                    navigate(
                        generatePath(Routes.modelEditorPreview.path, {
                            projectId,
                            shotId,
                        }),
                        { replace: true, state: PreviewMode.enter },
                    );
                }}>
                <Text>{t("editor.buttons.preview")}</Text>
            </ActionButton>
        ) : (
            <ActionButton
                onPress={() => {
                    navigate(
                        generatePath(Routes.modelEditor.path, {
                            projectId,
                            shotId,
                        }),
                        {
                            replace: true,
                            state: PreviewMode.exit,
                        },
                    );
                }}>
                <Text>{t("editor.buttons.stop")}</Text>
            </ActionButton>
        );
    }

    // If this is true, BabylonJS has given an error and is unable to load the model. We give the studio a callback
    // to this set function, so the webviewer can set this to true if an error is enountered, and we show an error page
    const [hasStudioError, setHasStudioError] = useState(false);

    return (
        <Flex
            key={isPreviewMode ? "preview" : "editor"}
            direction="column"
            width="100%"
            height="100%">
            {AppBarPortal && (
                <AppBarPortal>
                    <EditorNav shotName={shotName}>
                        {canEnterPreviewMode && renderPreviewButton()}
                        <ActionButton
                            onPress={backToProject}
                            isDisabled={isPreviewMode}
                            aria-label={t("editor.buttons.close")}>
                            {t("editor.buttons.close")}
                        </ActionButton>
                        <ActionButton
                            onPress={save}
                            isDisabled={isPreviewMode}
                            aria-label={t("editor.buttons.saveClose")}>
                            {t("editor.buttons.saveClose")}
                        </ActionButton>
                    </EditorNav>
                </AppBarPortal>
            )}
            {hotSpots && (
                <Flex
                    width="100%"
                    height="100%"
                    direction="column"
                    justifyContent="center"
                    alignItems="center">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            minHeight: "100%",
                            maxWidth: "100%",
                            padding: "var(--spectrum-global-dimension-size-400)",
                        }}>
                        {hasStudioError ? (
                            <ViewerError />
                        ) : isPreviewMode ? (
                            <Studio
                                initialHotSpots={hotSpots}
                                initialCameraOverride={
                                    shot?.overrides
                                        ?.camera as unknown as CameraOverride
                                }
                                enableLimitedZoom={isPreviewMode} // Unlimited zoom, except when previewing user experience
                                modelUrl={shotModel?.url}
                                iblUrl={constructIblPath(iblFilename)}
                                cameraName={shotModel?.cameraName}
                                tools={[
                                    StudioTool.cameraControls,
                                    StudioTool.frameButton,
                                    StudioTool.hotSpotsViewer,
                                ]}
                                strings={editorStrings}
                                aspectRatio={shot?.cameraAspect}
                                renderSettings={renderSettings}
                                onModelLoadError={() => setHasStudioError(true)}
                            />
                        ) : (
                            <Studio
                                initialHotSpots={hotSpots}
                                initialCameraOverride={shot?.overrides?.camera}
                                enableLimitedZoom={isPreviewMode} // Unlimited zoom, except when previewing user experience
                                modelUrl={shotModel?.url}
                                iblUrl={constructIblPath(iblFilename)}
                                cameraName={shotModel?.cameraName}
                                onHotSpotsUpdate={(spots) =>
                                    updateHotSpots(spots)
                                }
                                onCameraOverrideUpdate={(
                                    override: CameraOverride,
                                ) => setCameraOverride(override)}
                                tools={editorTools}
                                strings={editorStrings}
                                aspectRatio={shot?.cameraAspect}
                                renderSettings={renderSettings}
                                onModelLoadError={() => setHasStudioError(true)}
                            />
                        )}
                    </div>
                </Flex>
            )}
        </Flex>
    );
}
