/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Item,
    Menu,
    MenuTrigger,
    Section,
} from "@adobe/react-spectrum";
import More from "@spectrum-icons/workflow/More";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useRenameAssets } from "../hooks/useRenameAsset";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";
import { useAppContext } from "@src/contexts/AppContext";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import type { LibraryAssetCardItem } from "@src/contexts/RpcContext";
import { rpc } from "@src/contexts/RpcContext";
import { DeleteConfirmDialog } from "@src/interfaces/common/components/DeleteConfirmDialog";
import { useRedirects } from "@src/interfaces/common/hooks/useRedirects";
import { useUpload } from "@src/interfaces/common/hooks/useUpload";
import { DetailViewType } from "@src/interfaces/detail/components/common/DetailViewTabs";
import { useDownload } from "@src/interfaces/library/hooks/useDownload";

import type { TRPCClientError } from "@trpc/client";
import type { AnyRouter } from "@trpc/server";
import type { Key } from "react";

export type MenuAction =
    | "rename"
    | "cancel"
    | "download"
    | "upload"
    | "delete"
    | "invite"
    | "share"
    | "review"
    | "revert";

export function AssetCardActionMenu({
    id,
    name,
    isDetailView = false,
    detailViewType = DetailViewType.views2D,
    setAssetToUpgrade,
}: {
    id: string;
    name: string;
    isDetailView?: boolean;
    detailViewType?: DetailViewType;
    setAssetToUpgrade: (asset: LibraryAssetCardItem) => void;
}) {
    const { t } = useTranslation(["library", "common"]);
    const {
        showToastForInvalidPermissions,
        showToastForAssetUsedInProjects,
        success,
    } = useMessagingContext();
    const { assetDetailsRedirectToPrevious } = useRedirects();
    const { logger } = useAppContext();
    const [isOpen, setIsOpen] = useState(false);
    const { logIngestEvent } = useAnalyticsContext();

    const [isAssetLoaded, setIsAssetLoaded] = useState<boolean>(false);
    const [assetToDelete, setAssetToDelete] = useState<LibraryAssetCardItem>();

    const { client } = rpc.useUtils();
    const cancelAssetMutation = rpc.libraryAssets.cancel.useMutation();
    const deleteAssetMutation = rpc.libraryAssets.delete.useMutation();
    const revertAssetMutation = rpc.libraryAssets.revert.useMutation();

    const {rename} = useRenameAssets(id, name);

    const { data: asset } = rpc.libraryAssets.getCardItem.useQuery(id, {enabled: isOpen});
    const { data: predecessor } =
        rpc.libraryAssets.getAssetPredecessor.useQuery(id, {enabled: isOpen});
    const {
        data: isAssetUsedInProjects,
        isSuccess: isDoneFetchingUsedInProjects,
    } = rpc.libraryAssets.isUsedInProjects.useQuery(id, {enabled: isOpen});

    useEffect(() => {
        if (asset) {
            setIsAssetLoaded(true);
        }
    }, [asset]);

    const [menuItems, setMenuItems] = useState<
        { key: string; children: { key: string; label: string }[] }[]
    >([]);

    useEffect(() => {
        if (isOpen) {
            client.libraryAssets.canUserModify
                .query(id)
                .then((canUserModify) => {
                    const newMenuItems = [];
                    const uploadDownloadSection = {
                        key: "uploadDownload",
                        children: [
                            {
                                key: "rename",
                                label: t("common:actions.rename"),
                            },
                            {
                                key: "download",
                                label: t("common:actions.download"),
                            },
                        ],
                    };
                    if (canUserModify) {
                        uploadDownloadSection.children.push({
                            key: "upload",
                            label: t("actions.uploadVersion"),
                        });
                    }
                    newMenuItems.push(uploadDownloadSection);

                    if (canUserModify) {
                        newMenuItems.push({
                            key: "cancelDelete",
                            children: [
                                (!asset?.uploadComplete ||
                                    !asset?.jobsCompleted) &&
                                !asset?.hasValidationErrors
                                    ? {
                                          key: "cancel",
                                          label: t("common:actions.cancel"),
                                      }
                                    : asset?.hasValidationErrors &&
                                      (asset?.version ?? 0) > 1
                                    ? {
                                          key: "revert",
                                          label: t("common:actions.revert"),
                                      }
                                    : {
                                          key: "delete",
                                          label: t("common:actions.delete"),
                                      },
                            ],
                        });
                    }

                    setMenuItems(newMenuItems);
                });
        }
    }, [
        asset?.uploadComplete,
        asset?.jobsCompleted,
        asset?.hasValidationErrors,
        asset?.version,
        isOpen,
    ]);

    const [isDownloadPending, setIsDownloadPending] = useState<boolean>(false);
    const { downloadAsset } = useDownload();
    const { data: downloadData, isFetching: downloadDataIsFetching } =
        rpc.libraryAssets.getArtifactsForDownload.useQuery(id, {
            enabled: isOpen && isAssetLoaded,
        });
    useEffect(() => {
        if (isDownloadPending && asset && downloadData) {
            downloadAsset(asset.name, asset.id, downloadData, logger);
            setIsDownloadPending(false);
            logDownloadStart();
        }
    }, [downloadData, asset]);

    const { cancelUpload } = useUpload();
    const handleCancel = () => {
        if (!asset?.uploadComplete && asset?.uploadId) {
            cancelUpload(asset?.uploadId);
        }
        if (!asset?.jobsCompleted || !asset?.uploadComplete) {
            cancelAssetMutation.mutateAsync(asset?.id || "").catch((e) => {
                if (
                    (e as TRPCClientError<AnyRouter>)?.data?.code ===
                    "FORBIDDEN"
                ) {
                    showToastForInvalidPermissions();
                } else {
                    throw e;
                }
            });
        }
        if (isDetailView) {
            assetDetailsRedirectToPrevious(detailViewType, predecessor?.id);
        }
    };

    const handleDelete = () => {
        deleteAssetMutation.mutateAsync(id).catch((e) => {
            const trpcErrorDataCode = (e as TRPCClientError<AnyRouter>)?.data
                ?.code;
            if (trpcErrorDataCode === "PRECONDITION_FAILED") {
                showToastForAssetUsedInProjects();
            } else if (trpcErrorDataCode === "FORBIDDEN") {
                showToastForInvalidPermissions();
            } else {

                console.error("Caught unanticipated error", e.message);
            }
        });
        if (isDetailView) {
            assetDetailsRedirectToPrevious(detailViewType, predecessor?.id);
        }
    };

    const handleRevert = () => {
        try {
            revertAssetMutation.mutate(id);
        } catch (err) {
            throw new Error("Failed to revert asset version");
        }
        if (isDetailView) {
            assetDetailsRedirectToPrevious(detailViewType, predecessor?.id);
        }
    };

    const logDownloadStart = () => {
        const pathToAccess = isDetailView ? "details-view" : "asset-card";
        logIngestEvent("assetDownloadStart", {
            "ui.path_to_access": pathToAccess,
        });
    };

    const handleMenuClick = (key: Key) => {
        if (!asset) {
            throw new Error("Trying to operate on a non-existent asset.");
        }

        switch (key) {
            case "rename":
                rename();
                break;
            case "download":
                if (!downloadData || downloadDataIsFetching) {
                    setIsDownloadPending(true);
                } else if (
                    asset &&
                    (downloadData.models || downloadData.orthoRenders)
                ) {
                    downloadAsset(asset.name, asset.id, downloadData, logger);
                    logDownloadStart();
                }
                success(t("common:toast.downloadRequested"));
                break;
            case "upload":
                setAssetToUpgrade(asset);
                break;
            case "cancel":
                handleCancel();
                return;
            case "delete":
                if (isAssetUsedInProjects) {
                    return showToastForAssetUsedInProjects();
                } else {
                    return setAssetToDelete(asset);
                }
            case "revert":
                handleRevert();
                return;
            default:
                console.error(`Unknown menu item ${key}`);
                return;
        }
    };

    const [disabledKeys, setDisabledKeys] = useState<MenuAction[]>([]);
    useEffect(() => {
        const newDisabledKeys: MenuAction[] = [];
        if (asset?.uploadFailed) {
            newDisabledKeys.push("rename");
            newDisabledKeys.push("upload");
            newDisabledKeys.push("download");
        }
        if (!asset?.uploadComplete || !asset?.jobsCompleted) {
            newDisabledKeys.push("upload");
            newDisabledKeys.push("download");
        } else if (asset?.approved || asset?.hasValidationErrors) {
            newDisabledKeys.push("upload");
        }
        if (!isDoneFetchingUsedInProjects) {
            newDisabledKeys.push("delete");
        }
        setDisabledKeys(newDisabledKeys);
    }, [
        asset?.uploadComplete,
        asset?.jobsCompleted,
        asset?.approved,
        asset?.hasValidationErrors,
        isDoneFetchingUsedInProjects,
    ]);

    return (
        <>
            <MenuTrigger direction="end" shouldFlip onOpenChange={setIsOpen}>
                <ActionButton
                    data-uia="asset-card-action-btn"
                    aria-label="Actions"
                    isQuiet
                    marginBottom="size-50">
                    <More size="S" />
                </ActionButton>
                <Menu
                    data-uia="asset-action-menu"
                    onAction={(key) => handleMenuClick(key as MenuAction)}
                    disabledKeys={disabledKeys}
                    width="size-2400"
                    items={menuItems}>
                    {(section) => (
                        <Section key={section.key} items={section.children}>
                            {(item) => <Item key={item.key}>{item.label}</Item>}
                        </Section>
                    )}
                </Menu>
            </MenuTrigger>
            {assetToDelete && (
                <DeleteConfirmDialog
                    name={assetToDelete.name}
                    onDismiss={() => {
                        setAssetToDelete(undefined);
                    }}
                    actionHandler={handleDelete}
                />
            )}
        </>
    );
}
