/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { downloadZip } from "@shared/client/src/download";
import { useTranslation } from "react-i18next";

import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";
import type {
    LibraryAssetDownloadData,
    LogErrorCode,
    LogResources,
} from "@src/contexts/RpcContext";
import { ModelTypes, OrthoTypes } from "@src/contexts/RpcContext";
import { useUploadDownloadContext } from "@src/contexts/UploadDownloadContext";
import type { HeliosLogger } from "@src/lib/services/HeliosLogger";

import type { DownloadConfig } from "@shared/client/src/download";

export function useDownload() {
    const { t } = useTranslation("common");
    const { logIngestEvent } = useAnalyticsContext();
    const { addDownload, removeDownload } = useUploadDownloadContext();

    // In terms of model files we want:
    //  - the source and the usdz source (if the og wasn't usdz)
    //  - the 2 ar file types (glb and ar)
    //  - the web glb file
    function downloadAsset(
        assetName: string,
        assetId: string,
        downloadData: LibraryAssetDownloadData,
        logger: HeliosLogger,
    ) {
        const downloadConfigs: Array<DownloadConfig> = [];
        const id = addDownload(assetName);
        // Add all of the model types
        Object.values(ModelTypes).forEach((type) => {
            if (!downloadData.models) return;
            const model =
                downloadData.models[type as keyof typeof downloadData.models];

            let name = "";
            if (type === "source" || type === "ar" ||
                (type === "web" && model.fileExtension === ".glb")) {
                name = `${assetName}-${type}${model.fileExtension}`;
            }

            if (name && model.url) {
                downloadConfigs.push({
                    url: model.url,
                    fileName: name,
                });
            }

            model.convertedModels?.forEach((converted) => {
                name = "";
                if (type === "ar" || (type === "web" && converted.fileExtension === ".glb")) {
                    name = `${assetName}-${type}${converted.fileExtension}`;
                } else if (type === "source" && converted.fileExtension === ".usdz") {
                    name = `${assetName}-2D${converted.fileExtension}`;
                }

                if (name && converted.url) {
                    downloadConfigs.push({
                        url: converted.url,
                        fileName: name,
                    });
                }
            });
        });

        // Add all of the 2D renders
        OrthoTypes.forEach((type) => {
            if (!downloadData.orthoRenders) return;
            const render = downloadData.orthoRenders[type];
            if (render.url) {
                downloadConfigs.push({
                    url: render.url,
                    fileName: `${assetName}-${render.type}.png`,
                });
            }
        });
        const resources: LogResources = [
            {
                dbId: assetId,
                dbTable: "LibraryAsset",
            },
        ];
        downloadZip(
            `${assetName}.zip`,
            downloadConfigs.filter(
                (downloadConfig) => !!downloadConfig?.url,
            ) as DownloadConfig[],
            [],
            {
                errorPrompt: t("download.error.file.prompt"),
                onProgress: (progress) => {
                    if (progress.state === "completed") {
                        logIngestEvent("assetDownloadSuccess");
                    }
                },
                logError: (errorType) => {
                    let errorCode: LogErrorCode | undefined;
                    switch (errorType) {
                        case "blobError":
                            errorCode = "2073";
                            break;
                        case "fetchError":
                            errorCode = "2076";
                            break;
                    }
                    if (errorCode) {
                        logger.logError({
                            errorCode,
                            resources,
                        });
                    }
                    logIngestEvent("assetDownloadError");
                },
            },
        ).catch(() => {
            logger.logError({
                errorCode: "2075",
                resources,
            });
            logIngestEvent("assetDownloadError");
        }).finally(() => removeDownload(id));
    }

    return {
        downloadAsset,
    };
}
