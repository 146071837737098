/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import Cookies from "js-cookie";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export function useLocale() {
    const { t } = useTranslation(["common"]);
    const [locale, setLocale] = useState(Cookies.get("i18next") || "en-US");

    const supportedLocales = useMemo(() => {
        return [
            { value: "en-US", language: "English (United States)" },
            { value: "fr-FR", language: "Français (France)" },
            { value: "de-DE", language: "Deutsch (Deutschland)" },
            { value: "ja-JP", language: "日本語 (日本)" },
        ];
    }, [t]);

    const changeLocale = (updateLocale: string) => {
        setLocale(updateLocale);
        Cookies.set('i18next', updateLocale, {path: "/"})
        window.location.reload();
    }

    return {
        supportedLocales,
        locale,
        changeLocale,
    };
}
