/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Button, Flex, Heading, View } from "@adobe/react-spectrum";
import Alert from "@spectrum-icons/workflow/Alert";
import { useTranslation } from "react-i18next";

import { ActivityAssetProgress } from "./ActivityAssetProgress";
import { ActivityMessage } from "./ActivityMessage";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import { rpc } from "@src/contexts/RpcContext";

export function ActivityPanel() {
    const { t } = useTranslation();

    const { data: assets } = rpc.libraryAssets.getAllListPaged.useQuery({
        filters: ["processing", "myAssets"],
        count: 50,
    });
    const { persistentMessages, removePersistentMessage } = useMessagingContext();

    function clearAllErrors() {
        persistentMessages.forEach(({id}) => {
            removePersistentMessage(id)
        })
    }

    return (
        <View
            backgroundColor="gray-100"
            padding="size-500"
            borderRadius="small"
            borderColor="transparent">
            <Flex direction="column" width="size-4600">
                <View
                    borderBottomColor="gray-400"
                    borderBottomWidth="thick"
                    marginBottom="size-250"
                    paddingBottom="size-125">
                    <Flex justifyContent="space-between">
                        <Heading level={2}>
                            {t("activity-panel.heading")}
                        </Heading>
                        <Flex>
                            {!!persistentMessages.length && (
                                <Alert color="negative" />
                            )}
                        </Flex>
                    </Flex>
                </View>
                {persistentMessages.length > 1 && (
                    <Flex justifyContent="end">
                        <Button variant="secondary" onPress={clearAllErrors} marginBottom="size-250">
                            {t("activity-panel.button-dismiss-errors")}
                        </Button>
                    </Flex>
                )}
                <Flex direction="column"  maxHeight="640px" UNSAFE_style={{overflow: "auto"}}>
                    {assets &&
                        assets.items.map((asset) => (
                            <ActivityAssetProgress
                                key={asset.id}
                                assetId={asset.id}
                            />
                        ))}
                    {persistentMessages.map((message) => (
                        <ActivityMessage key={message.id} message={message} />
                    ))}
                    {!assets?.items.length &&
                        !persistentMessages.length &&
                        t("activity-panel.empty")}
                </Flex>
            </Flex>
        </View>
    );
}
