/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Divider, Flex, View } from "@adobe/react-spectrum";
import Comment from "@spectrum-icons/workflow/Comment";
import Compare from "@spectrum-icons/workflow/Compare";
import Info from "@spectrum-icons/workflow/Info";
import WorkFlow from "@spectrum-icons/workflow/Workflow";
import { t } from "i18next";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { AssetDetailContent } from "./AssetDetailContent";
import { AssetDetailSidePanels } from "./AssetDetailSidePanels";
import { AssetDetailViewNav } from "./AssetDetailViewNav";
import { AssetValidationError } from "./AssetValidationError";
import {
    DetailViewRail,
    DETAIL_PANEL_KEYS,
    DetailViewRailButton,
} from "../common/DetailViewRail";
import type { DefaultPageProps } from "@src/app";
import { rpc } from "@src/contexts/RpcContext";
import { useUIStateContext } from "@src/contexts/UIStateContext";
import { Routes } from "@src/routes";

import type { DetailViewType } from "../common/DetailViewTabs";

export const AssetDetailView = ({ AppBarPortal }: DefaultPageProps) => {
    const { assetId = "", viewType = "" } = useParams();
    const [searchParams] = useSearchParams();
    const commentId = searchParams.get("commentId");

    const navigate = useNavigate();
    const { state } = useLocation();
    const { assetDetailPanelSelection, setAssetDetailPanelSelection } =
        useUIStateContext();
    const {data: orgConfigData} = rpc.orgs.getConfigs.useQuery();

    const backToAssetLibrary = () => {
        if(state?.from === "search") {
            window.history.back();
        } else {
            navigate(Routes.library.path);
        }
    };

    const { data: asset } = rpc.libraryAssets.get.useQuery(assetId);

    const sidePanelButtons: DetailViewRailButton[] = [
        {
            key: DETAIL_PANEL_KEYS.messages,
            label: t("library:detailView.sidePanel.label.messages"),
            icon: <Compare size="S" />,
        },
        {
            key: DETAIL_PANEL_KEYS.info,
            label: t("library:detailView.sidePanel.label.info"),
            icon: <Info size="S" />,
        },
    ];

    if (asset?.jobsCompleted) {
        sidePanelButtons.push({
            key: DETAIL_PANEL_KEYS.comment,
            label: t("library:detailView.sidePanel.label.comment"),
            icon: <Comment size="S" />,
        });
    }

    if (orgConfigData?.workfrontConfig?.enabled) {
        sidePanelButtons.push({
            key: DETAIL_PANEL_KEYS.workfront,
            label: t("library:detailView.sidePanel.label.workfront"),
            icon: <WorkFlow size="S" />,
        });
    }

    useEffect(() => {
        if (
            asset &&
            !asset.jobsCompleted &&
            assetDetailPanelSelection === "comment"
        ) {
            setAssetDetailPanelSelection("messages");
        }
    }, [asset?.jobsCompleted, assetDetailPanelSelection]);

    useEffect(() => {
        if (commentId) {
            setAssetDetailPanelSelection(DETAIL_PANEL_KEYS.comment);
        }
    }, [commentId]);

    return (
        <View height="100%" width="100%">
            {asset && (
                <View height="100%" backgroundColor="gray-75">
                    {AppBarPortal && (
                        <AppBarPortal>
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                                height="100%">
                                <AssetDetailViewNav
                                    id={asset.id}
                                    name={asset.name}
                                    jobsCompleted={asset.jobsCompleted}
                                    jobsFailed={asset.jobsFailed}
                                    onBackButtonClicked={backToAssetLibrary}
                                    currentViewType={viewType as DetailViewType}
                                    hasValidationErrors={
                                        asset.modelMetaData.source
                                            .validationErrors.length > 0
                                    }
                                />
                                <Divider
                                    orientation="vertical"
                                    size="S"
                                    marginStart="size-100"
                                    marginY="size-225"
                                />
                            </Flex>
                        </AppBarPortal>
                    )}
                    {asset.modelMetaData.source.validationErrors.length ? (
                        <AssetValidationError
                            id={asset.id}
                            compositeId={asset.compositeId}
                            name={asset.name}
                            isVersionUpgrade={asset.version > 1}
                            validationErrors={
                                asset.modelMetaData.source.validationErrors
                            }
                        />
                    ) : (
                        <Flex
                            direction="row"
                            justifyContent="space-between"
                            height="100%">
                            <AssetDetailContent
                                id={asset.id}
                                compositeId={asset.compositeId}
                                viewType={viewType as DetailViewType}
                                commentId={commentId ?? undefined}
                                selectedPanelKey={assetDetailPanelSelection}
                            />
                            <AssetDetailSidePanels
                                asset={asset}
                                selectedPanelKey={assetDetailPanelSelection}
                            />
                            <DetailViewRail
                                selected={assetDetailPanelSelection}
                                handleToggleSidePanel={
                                    setAssetDetailPanelSelection
                                }
                                buttons={sidePanelButtons}
                            />
                        </Flex>
                    )}
                </View>
            )}
        </View>
    );
};
